import { GetTagsResponseData } from 'types/adminService'
import $api from 'utils/http'

export default class AdministrationUserTagService {
    static async addTagToUser(userId: number, tagId: number): Promise<GetTagsResponseData> {
        return $api.put(`${process.env.REACT_APP_API_URL}admin/user-tag-managment/users/${userId}/tags/${tagId}`)
    }

    static async removeUserTag(userId: number, tagId: number): Promise<GetTagsResponseData> {
        return $api.delete(`${process.env.REACT_APP_API_URL}admin/user-tag-managment/users/${userId}/tags/${tagId}`)
    }
}
