import {
    AllConfiguredRolesResponseData,
    CreateNewRoleAPIParams,
    CreateRoleResponseData,
    RoleAPIParams,
} from 'types/adminService'
import $api from 'utils/http'

export default class AdministrationRoleService {
    static async getUserRoles(): Promise<AllConfiguredRolesResponseData> {
        return $api.get(`${process.env.REACT_APP_API_URL}admin/role-managment/roles`)
    }

    static async createNewRole(data: CreateNewRoleAPIParams): Promise<CreateRoleResponseData> {
        return $api.post(`${process.env.REACT_APP_API_URL}admin/role-managment/roles`, data)
    }

    static async changeRole(id: number, data: RoleAPIParams) {
        return $api.put(`${process.env.REACT_APP_API_URL}admin/role-managment/roles/${id}`, data)
    }

    static async removeRole(id: number) {
        return $api.delete(`${process.env.REACT_APP_API_URL}admin/role-managment/roles/${id}`)
    }
}
