import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback'
import { ErrorBoundary } from 'react-error-boundary'
import { useAppDispatch } from 'hooks/useRedux'
import { useEffect, useState } from 'react'
import { setCurrentPage } from 'store/slices/currentPageSlice'
import { Page } from 'types/common'
import AdminPanel from './AdminPanel/AdminPanel'
import { EditUsersContainer } from './editUsers/EditUserContainer/EditUsersContainer'
import { AlertColor } from '@mui/material'
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar'
import EditRolesContainer from './editRoles/EditRolesContainer'
import { fetchRoles } from 'store/slices/rolesSlice'
import EditGameTagsContainer from './editGameTags/EditGameTagsContainer'
import EditTagsContainer from './editTags/EditTagsContainer'
import EditUserTagsContainer from './editUserTags/EditUserTagsContainer'
import AdministrationGameService from 'services/AdministrationGameService'

export enum CurrentDisplayComponent {
    EditUsers,
    EditRoles,
    EditUsersTag,
    EditGamesTag,
    EditTags,
}

export const AdminPage = () => {
    const dispatch = useAppDispatch()
    const [currentDisplayComponent, setCurrentDisplayComponent] = useState<CurrentDisplayComponent>(
        CurrentDisplayComponent.EditUsers
    )
    const [responseMessage, setResponseMessage] = useState<string>('')
    const [openAlert, setOpenAlert] = useState<boolean>(false)
    const [severity, setSeverity] = useState<AlertColor>('success')

    useEffect(() => {
        dispatch(setCurrentPage(Page.Admin))
        dispatch(fetchRoles())
    }, [dispatch])

    const handleChangeCurrentDisplayComponent = (componentName: CurrentDisplayComponent) => {
        setCurrentDisplayComponent(componentName)
    }

    const handleAlertOpen = (severityStatus: AlertColor, responseMessage: string) => {
        setOpenAlert(true)
        setSeverity(severityStatus)
        setResponseMessage(responseMessage)
    }

    const handleAlertClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenAlert(false)
    }

    const updateGamesInfo = async () => {
        try {
            await AdministrationGameService.updateGamesInfo()
            handleAlertOpen('success', 'Request to update games info sent successfully')
        } catch (error) {
            handleAlertOpen('error', (error as Error).message)
            console.error((error as Error).message)
        }
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <AdminPanel
                handleChangeCurrentDisplayComponent={handleChangeCurrentDisplayComponent}
                updateGamesInfo={updateGamesInfo}
            />
            {currentDisplayComponent === CurrentDisplayComponent.EditUsers && (
                <EditUsersContainer handleAlertOpen={handleAlertOpen} />
            )}
            {currentDisplayComponent === CurrentDisplayComponent.EditRoles && (
                <EditRolesContainer handleAlertOpen={handleAlertOpen} />
            )}
            {currentDisplayComponent === CurrentDisplayComponent.EditTags && (
                <EditTagsContainer handleAlertOpen={handleAlertOpen} />
            )}
            {currentDisplayComponent === CurrentDisplayComponent.EditGamesTag && <EditGameTagsContainer />}
            {currentDisplayComponent === CurrentDisplayComponent.EditUsersTag && <EditUserTagsContainer />}
            <CustomSnackbar open={openAlert} onClose={handleAlertClose} severity={severity} message={responseMessage} />
        </ErrorBoundary>
    )
}
