import React from 'react'
import { Link } from 'react-router-dom'
import { ManageAccounts, ExitToApp, Logout } from '@mui/icons-material'
import { ROUTE } from 'consts/constants'
import { Page } from 'types/common'
import { IconButton } from '@mui/material'
import classNames from 'classnames'
import styles from './buttonsGroup.module.scss'

interface ButtonsGroupProps {
    isAllowAdminPage: boolean
    currentPage: Page
    handleLogout: () => void
    isMenuActive: boolean
    toggleMenuActive: () => void
}

const ButtonsGroup: React.FC<ButtonsGroupProps> = ({
    isAllowAdminPage,
    currentPage,
    handleLogout,
    isMenuActive,
    toggleMenuActive,
}) => {
    return (
        <div className={styles.btnGroup}>
            <div
                className={classNames(styles.burger, `${isMenuActive ? styles.burger_active : ''}`)}
                onClick={toggleMenuActive}
            >
                <span></span>
            </div>
            {isAllowAdminPage && currentPage !== Page.Admin && (
                <Link to={ROUTE.ADMIN}>
                    <ManageAccounts className={styles.iconAdmin} aria-label="admin" />
                </Link>
            )}
            {isAllowAdminPage && currentPage === Page.Admin && (
                <Link to={ROUTE.MAIN}>
                    <ExitToApp className={styles.iconAdmin} aria-label="main" />
                </Link>
            )}
            <IconButton color="inherit" onClick={handleLogout} className={styles.iconBtn}>
                <Logout className={styles.iconLogout} aria-label="logout" />
            </IconButton>
        </div>
    )
}

export default ButtonsGroup
