import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { logout } from 'store/slices/userAuthSlice'
import { fetchUserBalance } from 'store/slices/userDataSlice'
import { convertBalanceValueToCurrencyValue } from 'utils/userUtils'
import CreditForm from 'components/CreditForm'
import Header from './Header'

const HeaderContainer: React.FC = () => {
    const dispatch = useAppDispatch()

    const realBalance = useAppSelector((state) => state.user.currentBalanceData.real.value)
    const funBalance = useAppSelector((state) => state.user.currentBalanceData.fun.value)
    const userName = useAppSelector((state) => state.user.info?.nickname)
    const permissions = useAppSelector((state) => state.user.info?.role?.permissions)
    const isAuth = useAppSelector((state) => state.userAuth.isAuth)
    const { currency } = useAppSelector((state) => state.currencySettings)
    const currentPage = useAppSelector((state) => state.currentPage.currentPage)

    const [isOpenCreditForm, setIsOpenCreditForm] = useState<boolean>(false)

    const [realBalanceInCurrency, setRealBalanceInCurrency] = useState<number>(0)
    const [funBalanceInCurrency, setFunBalanceInCurrency] = useState<number>(0)

    useEffect(() => {
        setRealBalanceInCurrency(convertBalanceValueToCurrencyValue(realBalance, currency))
        setFunBalanceInCurrency(funBalance)
    }, [currency, funBalance, realBalance])

    const handleLogout = () => {
        dispatch(logout())
    }

    const handleClickOpenCreditForm = () => {
        setIsOpenCreditForm(true)
    }

    const handleAsyncAction = () => {
        dispatch(fetchUserBalance())
    }

    return (
        <>
            <Header
                isAuth={isAuth}
                realBalanceInCurrency={realBalanceInCurrency}
                funBalanceInCurrency={funBalanceInCurrency}
                handleLogout={handleLogout}
                userName={userName}
                handleClickOpenCreditForm={handleClickOpenCreditForm}
                currency={currency}
                permissions={permissions}
                currentPage={currentPage}
            />
            <CreditForm
                userAPIType="AuthenticatedUser"
                isOpenCreditForm={isOpenCreditForm}
                setIsOpenCreditForm={setIsOpenCreditForm}
                realBalanceInCurrency={realBalanceInCurrency}
                funBalanceInCurrency={funBalanceInCurrency}
                currency={currency}
                asyncAction={handleAsyncAction}
            />
        </>
    )
}

export default HeaderContainer
