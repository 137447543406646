export enum IntegrationBackend {
    Legacy = 'LEGACY',
    OSI = 'OSI',
}

export enum Page {
    Main = 'main',
    Admin = 'admin',
    Demo = 'demo',
    Login = 'login',
    Environments = 'environments',
    LiveEnvMonitoring = 'liveEnvMonitoring',
}

export enum UserFormType {
    AddUser = 'AddUser',
    ChangeUser = 'ChangeUser',
}

export enum Currency {
    EUR = 'EUR',
    RUB = 'RUB',
    FUN = 'FUN',
}

export enum IconsCurrency {
    EUR = '€',
    RUB = '₽',
    FUN = 'F',
}

export enum BalanceMode {
    REAL = 'REAL',
    FUN = 'FUN',
}

export enum SortOrder {
    Ascending = 'Ascending',
    Descending = 'Descending',
}

export enum CategorySort {
    DeployDate = 'Deploy date',
    GameClass = 'Game class',
}

export enum GameSortOrder {
    NewestFirst = 'NewestFirst',
    OldestFirst = 'OldestFirst',
    ZtoA = 'ZtoA',
    AtoZ = 'AtoZ',
}

export enum Environment {
    DEV,
    STAGE,
    CERT,
    LIVE,
}
