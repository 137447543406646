import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Currency, IconsCurrency } from 'types/common'

interface CurrencyState {
    currencyIcon: IconsCurrency
    currency: Currency
}

const initialState: CurrencyState = {
    currencyIcon: IconsCurrency.EUR,
    currency: Currency.EUR,
}

const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {
        setCurrency: (state, action: PayloadAction<Currency>) => {
            const currencyIcon = IconsCurrency[action.payload] || initialState.currencyIcon
            return {
                currencyIcon,
                currency: action.payload,
            }
        },
    },
})

export const { setCurrency } = currencySlice.actions

export default currencySlice.reducer
