import React from 'react'
import { useAppSelector } from 'hooks/useRedux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { PermissionTitle, User } from 'types/user'
import { ROUTE } from 'consts/constants'

interface Props {
    requiredPermissions: PermissionTitle[]
}

export const RequireAuth: React.FC<Props> = ({ requiredPermissions }) => {
    const isAuth = useAppSelector((state) => state.userAuth.isAuth)
    const user = useAppSelector((state) => state.user.info)
    const location = useLocation()

    if (!isAuth) return <Navigate to={ROUTE.LOGIN} />

    const userHasAccess = user && hasAnyPermission(user, requiredPermissions)

    return userHasAccess ? <Outlet /> : <Navigate to={ROUTE.LOGIN} state={{ from: location }} />
}

export function hasAnyPermission(user: User, requiredPermissions: PermissionTitle[]): boolean {
    const userPermissions = user.role.permissions.map((permission) => permission.title)
    return requiredPermissions.some((permission) => userPermissions.includes(permission))
}
