import { Game } from 'types/gameService'
import GamesTableRow from './GamesTableRow/GamesTableRow'
import {
    styled,
    tableCellClasses,
    TableCell,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material'
import styles from './gamesTable.module.scss'
import { sortGamesByClass } from 'utils/gameUtils'
import { GameSortOrder } from 'types/common'

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

interface GamesTableProps {
    games: Game[]
}

const GamesTable: React.FC<GamesTableProps> = ({ games }) => {
    const sortedGamesByGameClass = sortGamesByClass(games, GameSortOrder.AtoZ)

    return (
        <TableContainer className={styles.tableContainer} component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead className={styles.tableHead}>
                    <TableRow>
                        <StyledTableCell align="center">#</StyledTableCell>
                        <StyledTableCell align="center">gameClass</StyledTableCell>
                        <StyledTableCell align="center">fileName</StyledTableCell>
                        <StyledTableCell align="center">fileHash</StyledTableCell>
                        <StyledTableCell align="center">gameVersion</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedGamesByGameClass.map((game, index) => (
                        <GamesTableRow game={game} key={index} orderNumber={index + 1} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default GamesTable
