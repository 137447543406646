import $api from 'utils/http'
import { Balance } from 'types/user'

interface DataBalance {
    balance: Balance[]
}

interface DataBalanceResponse {
    data: DataBalance
}

export default class UserService {
    static async getBalanceAuthenticatedUser(): Promise<DataBalanceResponse> {
        return $api.get(`${process.env.REACT_APP_API_URL}user/balance`)
    }

    static async setBalanceAuthenticatedUser({ balance }: { balance: Balance[] }): Promise<DataBalanceResponse> {
        return $api.post(`${process.env.REACT_APP_API_URL}user/balance`, { balance })
    }
}
