import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DEFAULT_SELECTED_LICENSED_SITE, LANGUAGE, SERVICE } from 'consts/constants'
import { IntegrationBackend, CategorySort, GameSortOrder } from 'types/common'
interface ConfigUIState {
    integrationBackend: IntegrationBackend
    selectedLicensedSite: string
    isOnlyWorkingGames: boolean
    isOnlyClassesA: boolean
    service: string
    environment: string
    isFullScreenGame: boolean
    categorySort: CategorySort
    sortOrder: GameSortOrder
    isSimpleLaunchGameMode: boolean
    globalLanguage: string
}

const initialState: ConfigUIState = {
    integrationBackend: IntegrationBackend.Legacy,
    selectedLicensedSite: DEFAULT_SELECTED_LICENSED_SITE.NAME,
    isOnlyWorkingGames: true,
    isOnlyClassesA: true,
    service: SERVICE.LCS,
    environment: '',
    isFullScreenGame: true,
    categorySort: CategorySort.DeployDate,
    sortOrder: GameSortOrder.NewestFirst,
    isSimpleLaunchGameMode: false,
    globalLanguage: LANGUAGE.EN,
}

const configUISlice = createSlice({
    name: 'configUI',
    initialState,
    reducers: {
        setIntegrationBackend: (state, action: PayloadAction<IntegrationBackend>) => {
            state.integrationBackend = action.payload
        },
        setSelectedLicensedSite: (state, action: PayloadAction<string>) => {
            state.selectedLicensedSite = action.payload
        },
        setFilterWorkingGames: (state) => {
            state.isOnlyWorkingGames = !state.isOnlyWorkingGames
        },
        setFilterOnlyClassesA: (state) => {
            state.isOnlyClassesA = !state.isOnlyClassesA
        },
        setSortOrder: (state, action: PayloadAction<GameSortOrder>) => {
            state.sortOrder = action.payload
        },
        setCategorySort: (state, action: PayloadAction<CategorySort>) => {
            state.categorySort = action.payload
        },
        setService: (state, action: PayloadAction<string>) => {
            state.service = action.payload
        },
        setEnvironment: (state, action: PayloadAction<string>) => {
            state.environment = action.payload
        },
        setGameScreen: (state) => {
            state.isFullScreenGame = !state.isFullScreenGame
        },
        setLaunchGameMode: (state) => {
            state.isSimpleLaunchGameMode = !state.isSimpleLaunchGameMode
        },
        setGlobalLanguage: (state, action: PayloadAction<string>) => {
            state.globalLanguage = action.payload
        },
    },
})

export const {
    setIntegrationBackend,
    setSelectedLicensedSite,
    setFilterWorkingGames,
    setFilterOnlyClassesA,
    setSortOrder,
    setCategorySort,
    setService,
    setEnvironment,
    setGameScreen,
    setLaunchGameMode,
    setGlobalLanguage,
} = configUISlice.actions

export default configUISlice.reducer
