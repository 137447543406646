import { RoleInfo, Permission } from 'types/user'
import { Button, styled, TableCell, tableCellClasses } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import styles from './rolesTableRow.module.scss'

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

interface RolesTableRowProps {
    role: RoleInfo
    handleFormOpen: () => void
    removeRole: () => void
}

const RolesTableRow: React.FC<RolesTableRowProps> = ({ role, handleFormOpen, removeRole }) => {
    return (
        <StyledTableRow>
            <StyledTableCell align="center" component="th" scope="row">
                {role.id}
            </StyledTableCell>
            <StyledTableCell align="center">{role.title}</StyledTableCell>
            <StyledTableCell align="center">{role.description}</StyledTableCell>
            <StyledTableCell align="center">
                {role.permissions.map((permission: Permission) => (
                    <div key={permission.id}>{permission.title}</div>
                ))}
            </StyledTableCell>
            <StyledTableCell align="center">
                <div className={styles.btnGroup}>
                    <Button variant="contained" size="small" className={styles.button} onClick={handleFormOpen}>
                        Edit
                    </Button>
                    <Button className={styles.button} variant="contained" size="small" onClick={removeRole}>
                        Remove
                    </Button>
                </div>
            </StyledTableCell>
        </StyledTableRow>
    )
}

export default RolesTableRow
