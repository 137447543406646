import { Card, CardMedia } from '@mui/material'
import { Game, GameMode, RunGameParameters } from 'types/gameService'
import { LANGUAGE } from 'consts/constants'
import { Currency } from 'types/common'
import { useEffect, useState } from 'react'
import styles from './gameCardSimpleMode.module.scss'

interface GameCardSimpleModeProps {
    game: Game
    imagePath: string | undefined
    runGame: (gameParams: RunGameParameters) => void
}

const GameCardSimpleMode: React.FC<GameCardSimpleModeProps> = ({ game, imagePath, runGame }) => {
    const { gameUID, gameName, latestVersion, release } = game
    const [version, setVersion] = useState<string>('')

    useEffect(() => {
        if (latestVersion) {
            setVersion(latestVersion)
        } else if (release?.tag) {
            setVersion(release.tag)
        }
    }, [latestVersion, release])

    const handleClick = () => {
        runGame({
            mode: GameMode.REAL,
            gameUid: gameUID || '',
            version,
            language: LANGUAGE.EN,
            currency: Currency.EUR,
        })
    }

    return (
        <Card className={styles.imageWrapper} onClick={handleClick}>
            <CardMedia>
                <CardMedia sx={{ objectFit: 'fill' }} component="img" alt={gameName} image={imagePath} />
            </CardMedia>
        </Card>
    )
}

export default GameCardSimpleMode
