import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { LoginContainer } from '../LoginContainer/LoginContainer'
import { setCurrentPage } from 'store/slices/currentPageSlice'
import { Page } from 'types/common'
import { PermissionTitle } from 'types/user'
import { hasAnyPermission } from 'components/hoc/RequireAuth'
import { ROUTE } from 'consts/constants'

const RequiredPermissions = [
    PermissionTitle.DevGameAccess,
    PermissionTitle.StageGameAccess,
    PermissionTitle.CertGameAccess,
]

const LoginPage: React.FC = () => {
    const dispatch = useAppDispatch()
    const isAuth = useAppSelector((state) => state.userAuth.isAuth)
    const user = useAppSelector((state) => state.user.info)

    useEffect(() => {
        dispatch(setCurrentPage(Page.Login))
    }, [dispatch])

    const userHasAccessToMainPage = isAuth && user && hasAnyPermission(user, RequiredPermissions)

    return userHasAccessToMainPage ? (
        <Navigate to={ROUTE.MAIN} />
    ) : (
        <div id="loginBg" className="loginBg">
            <LoginContainer />
        </div>
    )
}

export default LoginPage
