import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

export const Preloader: React.FC = () => {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: '50vh',
                left: 0,
                right: 0,
                textAlign: 'center',
            }}
        >
            <CircularProgress />
        </Box>
    )
}
