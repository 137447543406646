import { GameSortOrder, IntegrationBackend } from 'types/common'
import { Game } from 'types/gameService'

export function sortGamesByClass(games: Game[], sortingType: GameSortOrder): Game[] {
    if (sortingType === GameSortOrder.AtoZ) {
        return games.slice().sort((a, b) => a.gameClass.localeCompare(b.gameClass))
    } else {
        return games.slice().sort((a, b) => b.gameClass.localeCompare(a.gameClass))
    }
}

export function sortGamesByDeployDate(games: Game[], sortingType: GameSortOrder): Game[] {
    const formatDate = (timestamp: string): Date => {
        const [datePart, timePart] = timestamp.split(' ')
        const [day, month, year] = datePart.split('.')
        return new Date(`${year}-${month}-${day}T${timePart}`)
    }

    const compareDates = (a: Game, b: Game): number => {
        const dateA = a.release ? formatDate(a.release.timestamp).getTime() : Number.MAX_SAFE_INTEGER
        const dateB = b.release ? formatDate(b.release.timestamp).getTime() : Number.MAX_SAFE_INTEGER

        if (dateA === Number.MAX_SAFE_INTEGER && dateB === Number.MAX_SAFE_INTEGER) {
            return 0
        } else if (dateA === Number.MAX_SAFE_INTEGER) {
            return 1
        } else if (dateB === Number.MAX_SAFE_INTEGER) {
            return -1
        } else {
            return sortingType === GameSortOrder.NewestFirst ? dateB - dateA : dateA - dateB
        }
    }

    const sortedGames = [...games].sort(compareDates)
    return sortedGames
}

export function filterGamesByName(games: Game[], nameGame: string): Game[] {
    return games.slice().filter((game) => game.gameClass.toLowerCase().includes(nameGame.toLowerCase()))
}

export function filterWorkingGamesByIntegrationBackend(
    games: Game[],
    integrationBackend: IntegrationBackend,
    licensedSite: string
): Game[] {
    return games.filter((game) => game.gameStatusForIntegrationBackend[integrationBackend][licensedSite] === 'OK')
}

export function filterWorkingGames(games: Game[]): Game[] {
    return games.filter(
        (game) =>
            game.gameStatusForIntegrationBackend.LEGACY.LIGHT_WALLET_PORTUGAL_SITE === 'OK' ||
            game.gameStatusForIntegrationBackend.LEGACY.LIGHT_WALLET_SPAIN_SITE === 'OK' ||
            game.gameStatusForIntegrationBackend.LEGACY.LIGHT_WALLET_2_SITE === 'OK' ||
            game.gameStatusForIntegrationBackend.OSI.LIGHT_WALLET_SITE_OSI === 'OK'
    )
}

export function filterGamesByMathClassA(games: Game[]): Game[] {
    return games.filter((game) => game.gameClass.endsWith('a'))
}

export function extractGameInfo(games: Game[], gameClass: Game['gameClass']): Game | undefined {
    return games.find((game) => game.gameClass === gameClass)
}

export function extractGameTimestampAndVersion(game: Game): string {
    const { versions, release, latestVersion } = game
    if (release) {
        return `${release.tag} - ${release.timestamp}`
    } else if (versions && latestVersion) {
        return `${latestVersion} - ${versions[latestVersion].modified}`
    } else if (latestVersion) {
        return latestVersion
    }
    return ''
}

export function gameVersionsEqual(gameA: Game, gameB: Game): boolean {
    const versionA = extractGameTimestampAndVersion(gameA)
    const versionB = extractGameTimestampAndVersion(gameB)

    const trimmedVersionA = versionA.split(' ')[0].toLowerCase()
    const trimmedVersionB = versionB.split(' ')[0].toLowerCase()

    return trimmedVersionA === trimmedVersionB
}
