import { GameInfoResponseData } from 'types/adminService'
import $api from 'utils/http'

export default class AdministrationGameService {
    static async getGamesInfo(): Promise<GameInfoResponseData> {
        return $api.get(`${process.env.REACT_APP_API_URL}admin/game-managment/games`)
    }

    static async updateGamesInfo() {
        return $api.post(`${process.env.REACT_APP_API_URL}admin/game-managment/games`)
    }
}
