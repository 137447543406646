import { useCallback, useEffect, useState } from 'react'
import { Game } from 'types/gameService'
import GameService from 'services/GameService'

function useFetchGames(initialEnvironment: string): [boolean, Game[], (environment: string) => void] {
    const [environment, setEnvironment] = useState<string>(initialEnvironment)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [games, setGames] = useState<Game[]>([])

    useEffect(() => {
        const fetchGames = async () => {
            if (!environment) return

            setIsLoading(true)
            try {
                const response = await GameService.getGames(environment)
                setGames(response.data.games)
            } catch (error) {
                console.error((error as Error).message)
            } finally {
                setIsLoading(false)
            }
        }

        fetchGames()
    }, [environment])

    const changeEnvironment = useCallback((newEnvironment: string) => {
        setEnvironment(newEnvironment)
    }, [])

    return [isLoading, games, changeEnvironment]
}

export default useFetchGames
