import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import GameService from 'services/GameService'
import { Game } from 'types/gameService'

interface GamesState {
    games: Game[]
    loading: boolean
    error: string | null | undefined
}

const initialState: GamesState = {
    games: [],
    loading: false,
    error: null,
}

interface FetchGamesProps {
    environment: string
}

export const fetchGamesByEnvironment = createAsyncThunk<Game[], FetchGamesProps, { rejectValue: string }>(
    'games/fetchGames',
    async function ({ environment }, { rejectWithValue }) {
        try {
            const response = await GameService.getGames(environment)
            return response.data.games
        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    }
)

const gamesSlice = createSlice({
    name: 'games',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchGamesByEnvironment.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(fetchGamesByEnvironment.fulfilled, (state, action) => {
                state.loading = false
                state.games = action.payload
            })
            .addCase(fetchGamesByEnvironment.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
    },
})

export default gamesSlice.reducer
