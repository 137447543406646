import React, { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import useFetchGames from 'hooks/useFetchGames'
import { fetchLegacyBackendGameURL, fetchOSIBackendGameURL, setCurrentGameURL } from 'store/slices/currentGameSlice'
import { fetchUserBalance } from 'store/slices/userDataSlice'
import { Main } from './Main'
import { Iframe } from 'components/Iframe'
import { Currency, IntegrationBackend } from 'types/common'
import { DEFAULT_SELECTED_LICENSED_SITE, EXIT_URL, INTEGRATION, LANGUAGE } from 'consts/constants'
import { EnvLanguage, GameMode, RunGameParameters } from 'types/gameService'
import CreditForm from '../../CreditForm'
import { convertBalanceValueToCurrencyValue } from '../../../utils/userUtils' // Assuming CreditForm is imported correctly
import GameService from 'services/GameService'
import { Preloader } from 'components/Preloader/Preloader'
import { setGlobalLanguage, setSelectedLicensedSite } from 'store/slices/configUISlice'
import BackendService from 'services/BackendService'
import { LicensedSite } from 'types/backendService'

export const MainContainer: React.FC = () => {
    const dispatch = useAppDispatch()
    const URLGame = useAppSelector((state) => state.currentGame.URL)
    const currentPage = useAppSelector((state) => state.currentPage.currentPage)
    const {
        real: { value: creditReal },
        fun: { value: creditFun },
    } = useAppSelector((state) => state.user.currentBalanceData)
    const {
        integrationBackend,
        selectedLicensedSite,
        isOnlyWorkingGames,
        sortOrder,
        categorySort,
        isOnlyClassesA,
        service,
        environment,
        isFullScreenGame,
        globalLanguage,
    } = useAppSelector((state) => state.stateUI)

    const [isActiveIframe, setIsActiveIframe] = useState<boolean>(false)
    const [isOpenCreditForm, setIsOpenCreditForm] = useState<boolean>(false)
    const [balanceReal, setBalanceReal] = useState<number>(0)
    const [balanceFun, setBalanceFun] = useState<number>(0)
    const [currency, setCurrency] = useState<Currency>(Currency.EUR)
    const [isLoadingGames, games, changeEnvironment] = useFetchGames(environment)
    const [isLoadingEnvLanguageInfo, setIsLoadingEnvLanguageInfo] = useState<boolean>(true)
    const [envLanguages, setEnvLanguages] = useState<EnvLanguage[]>([])
    const [isLoadingLicensedSites, setIsLoadingLicensedSites] = useState<boolean>(true)
    const [licensedSites, setLicensedSites] = useState<LicensedSite[]>([])

    const [realBalanceInCurrency, setRealBalanceInCurrency] = useState<number>(0)
    const [funBalanceInCurrency, setFunBalanceInCurrency] = useState<number>(0)

    const iframeRef = useRef<HTMLIFrameElement>(null)

    useEffect(() => {
        setRealBalanceInCurrency(convertBalanceValueToCurrencyValue(balanceReal, currency))
        setFunBalanceInCurrency(balanceFun)
    }, [currency, balanceFun, balanceReal])

    useEffect(() => {
        changeEnvironment(environment)
    }, [changeEnvironment, environment])

    useEffect(() => {
        if (isFullScreenGame && URLGame) {
            window.open(URLGame, '_blank')
            dispatch(setCurrentGameURL(''))
        } else if (URLGame) {
            setIsActiveIframe(true)
        }
    }, [dispatch, URLGame, isFullScreenGame])

    useEffect(() => {
        const fetchEnvironmentLanguages = async () => {
            setIsLoadingEnvLanguageInfo(true)

            try {
                const response = await GameService.getLanguagesInfo(environment)
                const { languages } = response.data
                setEnvLanguages(languages)
                const language = languages.some((language) => language.id === globalLanguage)
                    ? globalLanguage
                    : LANGUAGE.EN

                dispatch(setGlobalLanguage(language))
            } catch (error) {
                console.error((error as Error).message)
            } finally {
                setIsLoadingEnvLanguageInfo(false)
            }
        }

        fetchEnvironmentLanguages()
    }, [dispatch, environment, globalLanguage])

    useEffect(() => {
        const fetchLicensedSiteList = async () => {
            setIsLoadingLicensedSites(true)

            try {
                const response = await BackendService.getLicensedSiteList(environment, integrationBackend)
                const { sites } = response.data
                setLicensedSites(sites)

                const selectedSiteName = getSelectedSiteName(sites, selectedLicensedSite)
                dispatch(setSelectedLicensedSite(selectedSiteName))
            } catch (error) {
                console.error((error as Error).message)
            } finally {
                setIsLoadingLicensedSites(false)
            }
        }

        const getSelectedSiteName = (sites: LicensedSite[], licensedSite: string) => {
            if (sites.some((site) => site.name === licensedSite)) {
                return licensedSite
            }
            if (sites.some((site) => site.name === DEFAULT_SELECTED_LICENSED_SITE.NAME)) {
                return DEFAULT_SELECTED_LICENSED_SITE.NAME
            }
            return sites[0].name
        }

        fetchLicensedSiteList()
    }, [dispatch, environment, integrationBackend, selectedLicensedSite])

    const runGame = (runGameParameters: RunGameParameters): void => {
        setCurrency(runGameParameters.currency as Currency)
        if (integrationBackend === IntegrationBackend.Legacy) {
            runLegacyIntegrationBackendGame(runGameParameters)
        } else {
            runOSIIntegrationBackendGame(runGameParameters)
        }
    }

    const runLegacyIntegrationBackendGame = ({ mode, gameUid, version, language, currency }: RunGameParameters) => {
        dispatch(
            fetchLegacyBackendGameURL({
                gameUid,
                mode,
                currency,
                language,
                version,
                gameBackend: service,
                env: environment,
                site: selectedLicensedSite,
            })
        )
    }

    const runOSIIntegrationBackendGame = ({ mode, gameUid, version, language, currency }: RunGameParameters) => {
        const amount = mode === GameMode.REAL ? creditReal : creditFun

        dispatch(
            fetchOSIBackendGameURL({
                gameUid,
                mode,
                currency,
                integration: INTEGRATION.SEAMLESS,
                language,
                mobile: isMobile,
                amount,
                exitUrl: EXIT_URL,
                version,
                env: environment,
            })
        )
    }

    const closeModalIframe = (status: boolean): void => {
        setIsActiveIframe(status)
        dispatch(setCurrentGameURL(''))
        dispatch(fetchUserBalance())
    }

    const handleClickOpenCreditForm = () => {
        setIsOpenCreditForm(true)
        setBalanceReal(creditReal)
        setBalanceFun(creditFun)
    }

    const sendMessageToIframe = (): void => {
        const message = { name: 'updateBalance' }
        const currentIframe = iframeRef.current

        if (currentIframe && currentIframe.contentWindow) {
            currentIframe.contentWindow.postMessage(message, '*')
        }
    }

    const handleAsyncAction = () => {
        dispatch(fetchUserBalance())
    }

    const isLoading = isLoadingGames && isLoadingEnvLanguageInfo && isLoadingLicensedSites

    return (
        <main className="main">
            {isLoading ? (
                <Preloader />
            ) : (
                <Main
                    games={games}
                    runGame={runGame}
                    service={service}
                    isOnlyWorkingGames={isOnlyWorkingGames}
                    isOnlyClassesA={isOnlyClassesA}
                    sortOrder={sortOrder}
                    categorySort={categorySort}
                    envLanguages={envLanguages}
                    licensedSites={licensedSites}
                />
            )}
            {URLGame && isActiveIframe && (
                <div>
                    <Iframe
                        URLGame={URLGame}
                        closeModalIframe={closeModalIframe}
                        handleClickOpenCreditForm={handleClickOpenCreditForm}
                        currentPage={currentPage}
                        iframeRef={iframeRef}
                    />
                    <CreditForm
                        userAPIType="AuthenticatedUser"
                        isOpenCreditForm={isOpenCreditForm}
                        setIsOpenCreditForm={setIsOpenCreditForm}
                        realBalanceInCurrency={realBalanceInCurrency}
                        funBalanceInCurrency={funBalanceInCurrency}
                        currency={currency}
                        asyncAction={handleAsyncAction}
                        sendMessageToIframe={sendMessageToIframe}
                    />
                </div>
            )}
        </main>
    )
}
