import React from 'react'
import { Outlet } from 'react-router-dom'
import HeaderContainer from './header/HeaderContainer'

export const MainLayout: React.FC = () => {
    return (
        <>
            <HeaderContainer />
            <Outlet />
        </>
    )
}
