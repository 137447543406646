import React from 'react'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import styles from './customSelect.module.scss'
import { getMenuProps } from 'utils/muiUtils'

interface SelectOption {
    value: string
    label: string
}

interface SelectProps {
    label: string
    value: string
    options: SelectOption[]
    onChange: (event: SelectChangeEvent<string>) => void
    disabled?: boolean
}

const CustomSelect: React.FC<SelectProps> = ({ label, value, options, onChange, disabled }) => {
    return (
        <FormControl variant="standard" disabled={disabled}>
            <InputLabel htmlFor={`${label.toLowerCase()}-label`}>{label}</InputLabel>
            <Select
                className={styles.select}
                inputProps={{ id: `${label.toLowerCase()}-label` }}
                label={label}
                value={value}
                onChange={onChange}
                MenuProps={getMenuProps()}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default CustomSelect
