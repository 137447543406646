import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback'
import { Preloader } from 'components/Preloader/Preloader'
import { ErrorBoundary } from 'react-error-boundary'
import GameTagsTable from './gameTagsTable/GameTagsTable'
import { GameTag } from 'types/adminService'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useEffect } from 'react'
import { fetchGamesInfo } from 'store/slices/allGamesInfoSlice'
import AdministrationTagService from 'services/AdministrationTagService'
import { fetchTags } from 'store/slices/tagsSlice'
import AdministrationGameTagService from 'services/AdministrationGameTagService'
import { getTagIdByTitle } from 'utils/tagUtils'

const EditGameTagsContainer = () => {
    const dispatch = useAppDispatch()
    const { gamesInfo, isLoading: isLoadingGamesInfo } = useAppSelector((state) => state.allGamesInfo)
    const { tags, isLoading: isLoadingTags } = useAppSelector((state) => state.tags)

    useEffect(() => {
        dispatch(fetchGamesInfo())
        dispatch(fetchTags())
    }, [dispatch])

    const handleAddTag = async (newTag: GameTag) => {
        const tagId = getTagIdByTitle(tags, newTag.title)

        if (tagId) {
            await AdministrationGameTagService.addTagToGame(newTag.gameId, tagId)
        } else {
            await AdministrationTagService.createNewTag(newTag)
        }

        dispatch(fetchGamesInfo())
        dispatch(fetchTags())
    }

    const handleRemoveTag = async (gameId: number, tagId: number) => {
        await AdministrationGameTagService.removeGameTag(gameId, tagId)
        dispatch(fetchGamesInfo())
        dispatch(fetchTags())
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {isLoadingGamesInfo && isLoadingTags ? (
                <Preloader />
            ) : (
                <GameTagsTable
                    gamesInfo={gamesInfo}
                    tags={tags}
                    handleAddTag={handleAddTag}
                    handleRemoveTag={handleRemoveTag}
                />
            )}
        </ErrorBoundary>
    )
}

export default EditGameTagsContainer
