import { GameSettingInterface } from './GameSettingInterface'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { setGameScreen } from 'store/slices/configUISlice'

export const GameSettingInterfaceContainer: React.FC = () => {
    const { isFullScreenGame } = useAppSelector((state) => state.stateUI)
    const dispatch = useAppDispatch()

    const handleStatusFullScreen = () => {
        dispatch(setGameScreen())
    }

    return (
        <GameSettingInterface
            handleStatusFullScreen={handleStatusFullScreen}
            isFullScreenGame={isFullScreenGame}
        ></GameSettingInterface>
    )
}
