import ResetGame from './ResetGame'

interface ResetGameContainerProps {
    handleResetGameState: (gameMode: string) => Promise<void>
}

const ResetGameContainer: React.FC<ResetGameContainerProps> = ({ handleResetGameState }) => {
    return <ResetGame handleResetGameState={handleResetGameState} />
}

export default ResetGameContainer
