import { Link } from 'react-router-dom'
import styles from './navigationLink.module.scss'

interface NavigationLinkProps {
    to: string
    label: string
    toggleMenuActive: () => void
}

const NavigationLink: React.FC<NavigationLinkProps> = ({ to, label, toggleMenuActive }) => (
    <div className={styles.linkWrapper} onClick={toggleMenuActive}>
        <Link className={styles.link} to={to}>
            {label}
        </Link>
    </div>
)

export default NavigationLink
