import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AdminPanelState {
    isOpen: boolean
}

const initialState: AdminPanelState = {
    isOpen: false,
}

const adminPanelSlice = createSlice({
    name: 'adminPanel',
    initialState,
    reducers: {
        setAdminPanel: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload
        },
    },
})

export const { setAdminPanel } = adminPanelSlice.actions

export default adminPanelSlice.reducer
