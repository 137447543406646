import { GetTagsResponseData } from 'types/adminService'
import $api from 'utils/http'

export default class AdministrationGameTagService {
    static async addTagToGame(gameId: number, tagId: number): Promise<GetTagsResponseData> {
        return $api.put(`${process.env.REACT_APP_API_URL}admin/game-tag-managment/games/${gameId}/tags/${tagId}`)
    }

    static async removeGameTag(gameId: number, tagId: number): Promise<GetTagsResponseData> {
        return $api.delete(`${process.env.REACT_APP_API_URL}admin/game-tag-managment/games/${gameId}/tags/${tagId}`)
    }
}
