import React from 'react'
import { AppBar, Box, Toolbar, Typography, Button } from '@mui/material'
import { useAppSelector } from 'hooks/useRedux'
import { CurrentDisplayComponent } from '../AdminPage'
import { PermissionTitle } from 'types/user'
import styles from './adminPanel.module.scss'
import { userHasPermission } from 'utils/userUtils'

interface AdminPanelProps {
    handleChangeCurrentDisplayComponent: (componentName: CurrentDisplayComponent) => void
    updateGamesInfo: () => Promise<void>
}

const AdminPanel: React.FC<AdminPanelProps> = ({ handleChangeCurrentDisplayComponent, updateGamesInfo }) => {
    const permissions = useAppSelector((state) => state.user.info?.role.permissions)
    const isAdmin = permissions && userHasPermission(permissions, PermissionTitle.UserManagement)

    const adminPanelButtons = [
        { label: 'Edit users', component: CurrentDisplayComponent.EditUsers },
        { label: 'Edit roles', component: CurrentDisplayComponent.EditRoles },
        { label: 'Edit users tags', component: CurrentDisplayComponent.EditUsersTag },
        { label: 'Edit games tags', component: CurrentDisplayComponent.EditGamesTag },
        { label: 'Edit tags', component: CurrentDisplayComponent.EditTags },
    ]

    return (
        <Box sx={{ flexGrow: 1 }} className={styles.adminPanel}>
            <AppBar position="static" elevation={0} sx={{ backgroundColor: '#bbdefb' }}>
                <Toolbar className={styles.toolBar}>
                    <Typography className={styles.title} variant="h6" color="primary">
                        Admin panel
                    </Typography>

                    <div className={styles.buttonGroup}>
                        {isAdmin && (
                            <>
                                {adminPanelButtons.map(({ label, component }) => (
                                    <Button
                                        key={label}
                                        className={styles.button}
                                        variant="contained"
                                        onClick={() => handleChangeCurrentDisplayComponent(component)}
                                    >
                                        {label}
                                    </Button>
                                ))}

                                {/* Temporary functionality location, UI/UX to be improved */}
                                <Button className={styles.button} variant="contained" onClick={updateGamesInfo}>
                                    Update games info
                                </Button>
                            </>
                        )}
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default AdminPanel
