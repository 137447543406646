import axios from 'axios'
import $api from 'utils/http'
import { LoginResponseData } from 'types/authService'
import { hashString } from '../utils/cryptoUtils'

export default class AuthService {
    static async login(login: string, password: string): Promise<LoginResponseData> {
        const hashedPassword = hashString(password)

        return await axios.get(`${process.env.REACT_APP_API_URL}authentication/signin`, {
            params: {
                nickname: login,
                password: hashedPassword,
            },
        })
    }

    static async auth() {
        return $api.get(
            `${process.env.REACT_APP_API_URL}authentication/refresh?refreshToken=${localStorage.getItem('tokenR')}`
        )
    }
}
