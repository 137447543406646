import { Card, CardMedia, SelectChangeEvent } from '@mui/material'
import { Game, RunGameParameters } from 'types/gameService'
import GameControlPanel from './gameControlPanel/GameControlPanel'
import styles from './gameCardWithControlPanel.module.scss'

interface GameCardWithControlPanelProps {
    game: Game
    runGame: (gameParams: RunGameParameters) => void
    service: string
    selectedLanguage: string
    handleChangeLanguage: (event: SelectChangeEvent<string>) => void
    selectedCurrency: string
    handleChangeCurrency: (event: SelectChangeEvent<string>) => void
    version: string
    handleChangeVersionGame: (event: SelectChangeEvent<string>) => void
    imagePath: string | undefined
    handleResetGameState: (gameMode: string) => Promise<void>
}

const GameCardWithControlPanel: React.FC<GameCardWithControlPanelProps> = ({
    game,
    runGame,
    service,
    version,
    imagePath,
    handleChangeVersionGame,
    selectedLanguage,
    handleChangeLanguage,
    selectedCurrency,
    handleChangeCurrency,
    handleResetGameState,
}) => {
    return (
        <Card className={styles.card}>
            <CardMedia component="img" alt={`logo ${game.gameName}`} sx={{ height: '250px' }} image={imagePath} />
            <GameControlPanel
                game={game}
                runGame={runGame}
                service={service}
                version={version}
                handleChangeVersionGame={handleChangeVersionGame}
                selectedLanguage={selectedLanguage}
                handleChangeLanguage={handleChangeLanguage}
                selectedCurrency={selectedCurrency}
                handleChangeCurrency={handleChangeCurrency}
                imagePath={imagePath}
                handleResetGameState={handleResetGameState}
            ></GameControlPanel>
        </Card>
    )
}

export default GameCardWithControlPanel
