import React from 'react'
import { GameWithEnvironmentReleaseInfo } from 'types/gameService'
import { styled, TableCell, tableCellClasses } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import styles from './liveEnvMonitoringTableRow.module.scss'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

interface LiveEnvMonitoringTableRowProps {
    game: GameWithEnvironmentReleaseInfo
    orderNumber: number
}

const LiveEnvMonitoringTableRow: React.FC<LiveEnvMonitoringTableRowProps> = ({ game, orderNumber }) => {
    const {
        gameClass,
        mathFileName,
        mathFileHash,
        gameLiveReleaseInfo,
        gameDevReleaseInfo,
        liveAndDevHasEqualVersion,
    } = game

    const liveReleaseInfoClassName = liveAndDevHasEqualVersion ? '' : styles.unequalVersion

    return (
        <StyledTableRow>
            <StyledTableCell align="center">{orderNumber}</StyledTableCell>
            <StyledTableCell align="center">{gameClass}</StyledTableCell>
            <StyledTableCell align="center">{mathFileName}</StyledTableCell>
            <StyledTableCell align="center">{mathFileHash}</StyledTableCell>
            <StyledTableCell align="center" className={liveReleaseInfoClassName}>
                {gameLiveReleaseInfo}
            </StyledTableCell>
            <StyledTableCell align="center">{gameDevReleaseInfo}</StyledTableCell>
        </StyledTableRow>
    )
}

export default LiveEnvMonitoringTableRow
