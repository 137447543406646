import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback'
import { Preloader } from 'components/Preloader/Preloader'
import { ErrorBoundary } from 'react-error-boundary'
import UsersTagsTable from './usersTagsTable/UsersTagsTable'
import { UserTag } from 'types/adminService'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useEffect } from 'react'
import { fetchTags } from 'store/slices/tagsSlice'
import { fetchUsers } from 'store/slices/usersSlice'
import AdministrationTagService from 'services/AdministrationTagService'
import AdministrationUserTagService from 'services/AdministrationUserTagService'
import { getTagIdByTitle } from 'utils/tagUtils'

const EditUserTagsContainer = () => {
    const dispatch = useAppDispatch()
    const { users, isLoading: isLoadingUsers } = useAppSelector((state) => state.users)
    const { tags, isLoading: isLoadingTags } = useAppSelector((state) => state.tags)

    useEffect(() => {
        dispatch(fetchUsers())
        dispatch(fetchTags())
    }, [dispatch])

    const handleAddTag = async (newTag: UserTag) => {
        const tagId = getTagIdByTitle(tags, newTag.title)

        if (tagId) {
            await AdministrationUserTagService.addTagToUser(newTag.userId, tagId)
        } else {
            await AdministrationTagService.createNewTag(newTag)
        }

        dispatch(fetchUsers())
        dispatch(fetchTags())
    }

    const handleRemoveTag = async (gameId: number, tagId: number) => {
        await AdministrationUserTagService.removeUserTag(gameId, tagId)
        dispatch(fetchUsers())
        dispatch(fetchTags())
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {isLoadingUsers && isLoadingTags ? (
                <Preloader />
            ) : (
                <UsersTagsTable
                    users={users}
                    tags={tags}
                    handleAddTag={handleAddTag}
                    handleRemoveTag={handleRemoveTag}
                />
            )}
        </ErrorBoundary>
    )
}

export default EditUserTagsContainer
