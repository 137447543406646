import { createSlice } from '@reduxjs/toolkit'

interface EnvironmentPageUIState {
    isOnlyWorkingGames: boolean
}

const initialState: EnvironmentPageUIState = {
    isOnlyWorkingGames: false,
}

const environmentPageUISlice = createSlice({
    name: 'environmentPageUI',
    initialState,
    reducers: {
        setFilterWorkingGames: (state) => {
            state.isOnlyWorkingGames = !state.isOnlyWorkingGames
        },
    },
})

export const { setFilterWorkingGames } = environmentPageUISlice.actions

export default environmentPageUISlice.reducer
