import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import AuthService from 'services/AuthService'
import { setUserData } from './userDataSlice'
import { User } from 'types/user'

interface UserCredentials {
    login: string
    password: string
}

interface UserState {
    isAuth: boolean
    error: string | undefined | null
    isLoading: boolean
}

interface ErrorPayload {
    message: string
    code: number
}

const initialState: UserState = {
    isAuth: false,
    error: null,
    isLoading: true,
}

export const userAuth = createAsyncThunk<User, UserCredentials, { rejectValue: ErrorPayload }>(
    'userAuth/login',
    async function ({ login, password }, { rejectWithValue, dispatch }) {
        try {
            const response = await AuthService.login(login, password)

            const { accessToken, refreshToken, user } = response.data
            localStorage.setItem('token', accessToken)
            localStorage.setItem('tokenR', refreshToken)

            dispatch(setUserData(user))
            dispatch(setAuthData())
            return user
        } catch (error: any) {
            return rejectWithValue({
                message: error.response?.data?.message || error.message,
                code: error.response?.status,
            })
        }
    }
)

export const updateUserAuth = createAsyncThunk<User, void, { rejectValue: ErrorPayload }>(
    'userAuth/updateUserAuth',
    async function (_, { rejectWithValue, dispatch }) {
        try {
            const response = await AuthService.auth()
            const data = response.data
            dispatch(setAuthData(data))
            return data
        } catch (error: any) {
            return rejectWithValue({
                message: error.response?.data?.message || error.message,
                code: error.response?.status,
            })
        }
    }
)

const userAuthSlice = createSlice({
    name: 'userAuth',
    initialState,
    reducers: {
        setAuthData: (state) => {
            state.isAuth = true
            state.isLoading = false
        },
        logout: (state) => {
            localStorage.removeItem('token')
            localStorage.removeItem('tokenR')
            state.isAuth = false
            state.isLoading = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(userAuth.pending, (state) => {
                state.error = null
                state.isLoading = true
            })
            .addCase(userAuth.rejected, (state, action: PayloadAction<ErrorPayload | undefined>) => {
                state.isLoading = false
                if (action.payload) {
                    state.error = action.payload.message
                    if (action.payload.code === 401) {
                        alert(`Please check your username and password and try logging in again.`)
                    } else {
                        alert(
                            `An error occurred while trying to log in. Please try again later:\n${action.payload.message}`
                        )
                    }
                    console.error(action.payload.message)
                } else {
                    state.error = 'An unknown error occurred.'
                }
            })
            .addCase(updateUserAuth.rejected, (state, action: PayloadAction<ErrorPayload | undefined>) => {
                state.isLoading = false
                state.error = action.payload?.message
            })
    },
})

export const { setAuthData, logout } = userAuthSlice.actions

export default userAuthSlice.reducer
