import { ENVIRONMENT } from 'consts/constants'
import { BalanceMode, Currency, Page } from 'types/common'
import { Balance, Permission, PermissionTitle } from 'types/user'

export function extractUserBalance({
    userBalance,
    balanceMode,
    currency,
}: {
    userBalance: Balance[]
    balanceMode: BalanceMode
    currency: Currency
}): number | undefined {
    const balance = userBalance.find(({ mode }) => mode === balanceMode)?.value

    if (balance !== undefined) {
        return convertBalanceValueToCurrencyValue(balance, currency)
    }

    return balance
}

export function convertBalanceValueToCurrencyValue(balance: number, currency: Currency): number {
    if (currency !== Currency.RUB) {
        return Number((balance / 100).toFixed(2))
    }

    return balance
}

const permissionsToEnvsMappingForMainPage: Partial<Record<PermissionTitle, string>> = {
    [PermissionTitle.DevGameAccess]: ENVIRONMENT.DEV,
    [PermissionTitle.StageGameAccess]: ENVIRONMENT.STAGE,
    [PermissionTitle.CertGameAccess]: ENVIRONMENT.CERT,
}

const permissionsToEnvsMappingForOtherPages: Partial<Record<PermissionTitle, string>> = {
    [PermissionTitle.DevGameAccess]: ENVIRONMENT.DEV,
    [PermissionTitle.StageGameAccess]: ENVIRONMENT.STAGE,
    [PermissionTitle.CertGameAccess]: ENVIRONMENT.CERT,
    [PermissionTitle.LiveGameAccess]: ENVIRONMENT.LIVE,
}

export function getAllowedEnvironments(permissions: Permission[], page: Page): string[] {
    const mapping = page === Page.Main ? permissionsToEnvsMappingForMainPage : permissionsToEnvsMappingForOtherPages

    return permissions
        .map((permission) => mapping[permission.title])
        .filter((environment): environment is string => environment !== undefined)
}

export function getInitEnvironment(allowedEnvironments: string[]): string {
    if (!allowedEnvironments.length) {
        return ''
    }
    return allowedEnvironments[0]
}

export function userHasAccessToEnvironment(permissions: Permission[], environment: string): boolean {
    return permissions
        .map((permission) => permissionsToEnvsMappingForOtherPages[permission.title])
        .includes(environment)
}

export function userHasPermission(permissions: Permission[], permissionTitle: PermissionTitle): boolean {
    return permissions.some((permission) => permission.title === permissionTitle)
}
