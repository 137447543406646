import { Currency } from 'types/common'
import CreditReal from './CreditReal'
import { useAppDispatch } from 'hooks/useRedux'
import { setCurrency } from 'store/slices/currencySlice'

interface CreditRealContainerProps {
    handleClickOpenCreditForm: () => void
    realBalanceInCurrency: number
    currency: Currency
}

const CreditRealContainer: React.FC<CreditRealContainerProps> = ({
    handleClickOpenCreditForm,
    realBalanceInCurrency,
    currency,
}) => {
    const dispatch = useAppDispatch()

    const handleChangeCurrency = (currency: Currency) => {
        dispatch(setCurrency(currency))
    }

    return (
        <CreditReal
            handleClickOpenCreditForm={handleClickOpenCreditForm}
            handleChangeCurrency={handleChangeCurrency}
            realBalanceInCurrency={realBalanceInCurrency}
            currency={currency}
        />
    )
}

export default CreditRealContainer
