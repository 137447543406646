import { LicensedSiteListResponse } from 'types/backendService'
import { IntegrationBackend } from 'types/common'
import $api from 'utils/http'

export default class BackendService {
    static async getLicensedSiteList(env: string, backend: IntegrationBackend): Promise<LicensedSiteListResponse> {
        return $api.get(`${process.env.REACT_APP_API_URL}backend/sites`, {
            params: {
                env,
                backend,
            },
        })
    }
}
