import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AdministrationGameService from 'services/AdministrationGameService'
import { GameInfo } from 'types/adminService'

interface AllGamesInfoState {
    gamesInfo: GameInfo[]
    isLoading: boolean
    error: string | null | undefined
}

const initialState: AllGamesInfoState = {
    gamesInfo: [],
    isLoading: false,
    error: null,
}

export const fetchGamesInfo = createAsyncThunk<GameInfo[], void, { rejectValue: string }>(
    'gamesInfo/fetchGamesInfo',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AdministrationGameService.getGamesInfo()
            return response.data.games
        } catch (error) {
            return rejectWithValue((error as Error).message)
        }
    }
)

const allGamesInfoSlice = createSlice({
    name: 'gamesInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchGamesInfo.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchGamesInfo.fulfilled, (state, action) => {
                state.isLoading = false
                state.gamesInfo = action.payload
            })
            .addCase(fetchGamesInfo.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    },
})

export default allGamesInfoSlice.reducer
