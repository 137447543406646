import React, { useEffect, useState } from 'react'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField } from '@mui/material'
import { BalanceMode, Currency } from 'types/common'
import AdministrationUserService from 'services/AdministrationUserService'
import UserService from 'services/UserService'
import styles from './creditForm.module.scss'

interface CreditFormProps {
    userAPIType: 'RequestedUser' | 'AuthenticatedUser'
    isOpenCreditForm: boolean
    setIsOpenCreditForm: (value: boolean) => void
    realBalanceInCurrency: number
    funBalanceInCurrency: number
    userId?: number
    currency: Currency
    asyncAction: () => void
    sendMessageToIframe?: () => void
}

const CreditForm: React.FC<CreditFormProps> = ({
    userAPIType,
    isOpenCreditForm,
    setIsOpenCreditForm,
    realBalanceInCurrency,
    funBalanceInCurrency,
    userId,
    currency,
    asyncAction,
    sendMessageToIframe,
}) => {
    const [openAlert, setOpenAlert] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [severity, setSeverity] = useState<'success' | 'error'>('success')
    const [valueReal, setValueReal] = useState<string>('')
    const [valueFun, setValueFun] = useState<string>('')

    useEffect(() => {
        setValueReal(realBalanceInCurrency.toString())
        setValueFun(funBalanceInCurrency.toString())
    }, [realBalanceInCurrency, funBalanceInCurrency, currency])

    const handleCloseAlert = (_event_: Event | React.SyntheticEvent<any, Event>, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenAlert(false)
    }

    const handleClose = () => {
        setIsOpenCreditForm(false)
    }

    const handleSubmitAuthorizedUser = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        try {
            let realMoneyValueForRequest = Number(valueReal)
            const funMoneyValueForRequest = Number(valueFun)

            if (currency !== Currency.RUB) {
                realMoneyValueForRequest = Math.round(Number(valueReal) * 100)
            }

            const balance = [
                { mode: BalanceMode.REAL, value: realMoneyValueForRequest },
                { mode: BalanceMode.FUN, value: funMoneyValueForRequest },
            ]

            if (userAPIType === 'AuthenticatedUser') {
                await UserService.setBalanceAuthenticatedUser({ balance })
            } else if (userId) {
                await AdministrationUserService.setRequestedUserBalance({ id: userId, balance })
            }

            asyncAction()
            setOpenAlert(true)
            setSeverity('success')
            setResponseMessage('Credit successfully changed')
            handleClose()
            if (sendMessageToIframe) {
                sendMessageToIframe()
            }
        } catch (error) {
            setOpenAlert(true)
            setSeverity('error')
            setResponseMessage((error as Error).message)
            console.error((error as Error).message)
        }
    }

    return (
        <>
            <Dialog open={isOpenCreditForm} onClose={handleClose}>
                <DialogTitle className={styles.creditDialogTitle}>Change credit</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmitAuthorizedUser}>
                        <div className={styles.creditForm}>
                            <div className={styles.fieldForm}>
                                <TextField
                                    label="Credit Real"
                                    variant="standard"
                                    placeholder="Enter amount"
                                    autoFocus
                                    value={valueReal}
                                    type="number"
                                    InputProps={{ inputProps: { min: 0, step: currency === Currency.RUB ? 1 : 0.01 } }}
                                    onChange={(e) => setValueReal(e.target.value)}
                                />
                            </div>

                            <div className={styles.fieldForm}>
                                <TextField
                                    label="Credit Fun"
                                    variant="standard"
                                    placeholder="Enter amount"
                                    value={valueFun}
                                    type="number"
                                    InputProps={{ inputProps: { min: 0, step: currency === Currency.RUB ? 1 : 0.01 } }}
                                    onChange={(e) => setValueFun(e.target.value)}
                                />
                            </div>
                        </div>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button type="submit">Change</Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
            >
                <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }}>
                    {responseMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

export default CreditForm
