import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AdministrationUserService from 'services/AdministrationUserService'
import { User } from 'types/user'

interface UsersState {
    users: User[]
    isLoading: boolean
    error: string | null | undefined
}

const initialState: UsersState = {
    users: [],
    isLoading: false,
    error: null,
}

export const fetchUsers = createAsyncThunk<User[], void, { rejectValue: string }>(
    'users/fetchUsers',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AdministrationUserService.getUsers()
            return response.data.users
        } catch (error) {
            return rejectWithValue((error as Error).message)
        }
    }
)

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.isLoading = false
                state.users = action.payload
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    },
})

export default usersSlice.reducer
