import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { PlayCircleFilledOutlined } from '@mui/icons-material'
import { SERVICE } from 'consts/constants'
import { useAppSelector } from 'hooks/useRedux'
import { Game as GameInterface, GameMode, RunGameParameters } from 'types/gameService'
import ResetGameContainer from './ResetGame/ResetGameContainer'
import styles from './gameControlPanel.module.scss'
import { extractAndSortGameVersions } from 'utils/extractAndSortGameVersions'
import { getMenuProps } from 'utils/muiUtils'

interface GameProps {
    game: GameInterface
    runGame: (gameParams: RunGameParameters) => void
    service: string
    selectedLanguage: string
    handleChangeLanguage: (event: SelectChangeEvent<string>) => void
    selectedCurrency: string
    handleChangeCurrency: (event: SelectChangeEvent<string>) => void
    version: string
    handleChangeVersionGame: (event: SelectChangeEvent<string>) => void
    imagePath: string | undefined
    handleResetGameState: (gameMode: string) => Promise<void>
}

const GameControlPanel: React.FC<GameProps> = (props) => {
    const {
        game,
        runGame,
        service,
        selectedLanguage,
        handleChangeLanguage,
        selectedCurrency,
        handleChangeCurrency,
        version,
        handleChangeVersionGame,
        handleResetGameState,
    } = props

    const {
        gameName: title,
        gameStatusForIntegrationBackend,
        gameClass,
        currency,
        languages,
        gameUID: gameUid,
        versions,
        release,
    } = game

    const { integrationBackend, selectedLicensedSite } = useAppSelector((state) => state.stateUI)

    const isDmsService = service === SERVICE.DMS

    const gameVerionsArray = versions ? extractAndSortGameVersions(versions) : []
    const reversedGameVerionsArray = gameVerionsArray.reverse()

    return (
        <div className={styles.controlPanel}>
            <div className={styles.info}>
                <CardContent className={styles.gameNameBlock}>
                    <Typography className={styles.title} gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                </CardContent>
                <div className={styles.gameClass}>Class: {gameClass}</div>
            </div>

            <div className={styles.selectGroup}>
                <FormControl
                    variant="outlined"
                    size="small"
                    sx={{
                        width: 120,
                    }}
                >
                    <InputLabel htmlFor="language-label">Language</InputLabel>
                    <Select
                        inputProps={{ id: 'language-label' }}
                        label="Game language"
                        value={selectedLanguage}
                        onChange={(event) => handleChangeLanguage(event)}
                        MenuProps={getMenuProps()}
                    >
                        {languages &&
                            languages.map((language, index) => (
                                <MenuItem value={language} key={index}>
                                    {language}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>

                <FormControl
                    variant="outlined"
                    size="small"
                    sx={{
                        width: 120,
                        marginLeft: '8px',
                        padding: 0,
                    }}
                >
                    <InputLabel htmlFor="currency-label">Currency</InputLabel>
                    <Select
                        inputProps={{ id: 'currency-label' }}
                        label="Currency"
                        value={selectedCurrency}
                        onChange={(event) => handleChangeCurrency(event)}
                        MenuProps={getMenuProps()}
                    >
                        {currency &&
                            currency.map((currency, index) => (
                                <MenuItem value={currency} key={index}>
                                    {currency}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>

                {versions ? (
                    <FormControl className={styles.selectGameVersion} size="small">
                        <InputLabel htmlFor={`${gameClass} ${version}`} className={styles.versionLabel}>
                            Game version
                        </InputLabel>
                        <Select
                            inputProps={{ id: `${gameClass} ${version}` }}
                            value={version}
                            label="Game version"
                            onChange={handleChangeVersionGame}
                            MenuProps={getMenuProps()}
                        >
                            {reversedGameVerionsArray.map((gameVerion) => {
                                return (
                                    <MenuItem key={gameVerion} value={gameVerion}>
                                        {gameVerion}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                ) : release ? (
                    <TextField
                        className={styles.fieldGameVersion}
                        size="small"
                        id="outlined-basic"
                        label="Game version"
                        variant="outlined"
                        value={version}
                    />
                ) : null}
            </div>

            <CardActions
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: '10px',
                    mb: '15px',
                    padding: 0,
                    width: '95%',
                }}
            >
                <Button
                    sx={{ width: '120px', height: '30px', padding: 0 }}
                    variant="outlined"
                    startIcon={<PlayCircleFilledOutlined />}
                    onClick={() =>
                        runGame({
                            mode: GameMode.DEMO,
                            gameUid: gameUid || '',
                            version,
                            language: selectedLanguage,
                            currency: selectedCurrency,
                        })
                    }
                    disabled={gameStatusForIntegrationBackend[integrationBackend][selectedLicensedSite] !== 'OK'}
                >
                    {GameMode.DEMO}
                </Button>
                <Button
                    sx={{ width: '120px', height: '30px', padding: 0 }}
                    variant="outlined"
                    startIcon={<PlayCircleFilledOutlined />}
                    onClick={() =>
                        runGame({
                            mode: GameMode.REAL,
                            gameUid: gameUid || '',
                            version,
                            language: selectedLanguage,
                            currency: selectedCurrency,
                        })
                    }
                    disabled={gameStatusForIntegrationBackend[integrationBackend][selectedLicensedSite] !== 'OK'}
                >
                    {GameMode.REAL}
                </Button>
                <Button
                    sx={{ width: '120px', height: '30px', padding: 0 }}
                    variant="outlined"
                    startIcon={<PlayCircleFilledOutlined />}
                    onClick={() =>
                        runGame({
                            mode: GameMode.FUN,
                            gameUid: gameUid || '',
                            version,
                            language: selectedLanguage,
                            currency: selectedCurrency,
                        })
                    }
                    disabled={
                        gameStatusForIntegrationBackend[integrationBackend][selectedLicensedSite] !== 'OK' ||
                        isDmsService
                    }
                >
                    {GameMode.FUN}
                </Button>
            </CardActions>
            {service === SERVICE.LCS && <ResetGameContainer handleResetGameState={handleResetGameState} />}
        </div>
    )
}

export default GameControlPanel
