import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

interface ConfirmationDialogProps {
    isOpen: boolean
    title: string
    description: string
    onConfirm: () => void
    onClose: () => void
    confirmButtonText?: string
    cancelButtonText?: string
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    isOpen,
    title,
    description,
    onConfirm,
    onClose,
    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
}) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="confirmation-dialog-title"
            aria-describedby="confirmation-dialog-description"
        >
            <DialogTitle sx={{ textAlign: 'center' }} id="confirmation-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="confirmation-dialog-description">{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{cancelButtonText}</Button>
                <Button onClick={onConfirm} autoFocus>
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationDialog
