import { ChangeTagAPIParams, CreateNewTagAPIParams, GetTagsResponseData } from 'types/adminService'
import $api from 'utils/http'

export default class AdministrationTagService {
    static async getTags(): Promise<GetTagsResponseData> {
        return $api.get(`${process.env.REACT_APP_API_URL}admin/tag-managment/tags`)
    }

    static async createNewTag(data: CreateNewTagAPIParams) {
        return $api.post(`${process.env.REACT_APP_API_URL}admin/tag-managment/tags`, data)
    }

    static async removeTag(id: number) {
        return $api.delete(`${process.env.REACT_APP_API_URL}admin/tag-managment/tags/${id}`)
    }

    static async changeTagInfo(id: number, data: ChangeTagAPIParams) {
        return $api.put(`${process.env.REACT_APP_API_URL}admin/tag-managment/tags/${id}`, data)
    }
}
