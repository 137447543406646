import { useState, ChangeEvent, FormEvent } from 'react'
import { LoginForm } from '../LoginForm/LoginForm'
import { useAppDispatch } from 'hooks/useRedux'
import { userAuth } from 'store/slices/userAuthSlice'

export const LoginContainer: React.FC = () => {
    const dispatch = useAppDispatch()

    const [login, setLoginValue] = useState<string>('')
    const [password, setPasswordValue] = useState<string>('')

    const handleLogIn = (e: FormEvent) => {
        e.preventDefault()
        dispatch(userAuth({ login, password }))
    }

    const handleLoginChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLoginValue(e.target.value)
    }

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPasswordValue(e.target.value)
    }

    return (
        <LoginForm
            loginValue={login}
            handleLogIn={handleLogIn}
            handleLoginChange={handleLoginChange}
            handlePasswordChange={handlePasswordChange}
            passwordValue={password}
        />
    )
}
