import {
    styled,
    tableCellClasses,
    Button,
    TableCell,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material'
import { RoleInfo } from 'types/user'
import styles from './rolesTable.module.scss'
import RolesTableRow from './TableRow/RolesTableRow'

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

interface RolesTableProps {
    roles: RoleInfo[]
    handleFormOpen: (roleId?: number) => void
    handleRemoveRoleDialogOpen: (roleId: number) => void
}

const RolesTable: React.FC<RolesTableProps> = ({ roles, handleFormOpen, handleRemoveRoleDialogOpen }) => {
    return (
        <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead className={styles.tableHead}>
                    <TableRow>
                        <StyledTableCell align="center">Id</StyledTableCell>
                        <StyledTableCell align="center">Title</StyledTableCell>
                        <StyledTableCell align="center">Description</StyledTableCell>
                        <StyledTableCell align="center">Permissions</StyledTableCell>
                        <StyledTableCell align="center">
                            <Button className={styles.button} variant="contained" onClick={() => handleFormOpen()}>
                                Add new role
                            </Button>
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {roles.map((role) => (
                        <RolesTableRow
                            key={role.id}
                            role={role}
                            handleFormOpen={handleFormOpen.bind(undefined, role.id)}
                            removeRole={handleRemoveRoleDialogOpen.bind(undefined, role.id)}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default RolesTable
