import { SetStateAction, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { fetchUsers } from 'store/slices/usersSlice'
import AdministrationUserService from 'services/AdministrationUserService'
import { removeEmptyObjectValues } from 'utils/objectUtils'
import { ChangeUserAPIParams, CreateNewUserAPIParams } from 'types/adminService'
import { UserFormType } from 'types/common'
import { User } from 'types/user'
import {
    AlertColor,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
} from '@mui/material'
import styles from './userForm.module.scss'
import { Preloader } from 'components/Preloader/Preloader'

interface UserFormProps {
    user: User
    isAdmin: boolean
    userFormType: UserFormType
    titleUserForm: string
    isOpenUserForm: boolean
    setIsOpenUserForm: React.Dispatch<SetStateAction<boolean>>
    handleAlertOpen: (severityStatus: AlertColor, responseMessage: string) => void
}

const UserForm: React.FC<UserFormProps> = ({
    user,
    isAdmin,
    userFormType,
    titleUserForm,
    isOpenUserForm,
    setIsOpenUserForm,
    handleAlertOpen,
}) => {
    const dispatch = useAppDispatch()

    const { roles, isLoading: isRolesLoading } = useAppSelector((state) => state.roles)

    const defaultValues = useMemo(
        () => ({
            nickname: user.nickname,
            password: '',
            firstName: user.firstName,
            lastName: user.lastName,
            company: user.company,
            email: user.email,
            roleId: user.role?.id,
        }),
        [user]
    )

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues,
    })

    useEffect(() => {
        reset(defaultValues)
    }, [reset, defaultValues])

    useEffect(() => {
        if (userFormType === UserFormType.AddUser) {
            reset()
        }
    }, [reset, userFormType])

    const handleClose = () => {
        setIsOpenUserForm(false)
    }

    const onSubmitAddingUser = async (data: CreateNewUserAPIParams) => {
        try {
            const filteredData = removeEmptyObjectValues<CreateNewUserAPIParams>(data)
            await AdministrationUserService.createNewUser(filteredData)
            handleSuccess()
        } catch (error) {
            handleError(error)
        }
    }

    const onSubmitEditingUser = async (data: ChangeUserAPIParams) => {
        try {
            let filteredData = filterDataByDefaultValues(data, defaultValues)
            filteredData = removeEmptyObjectValues<ChangeUserAPIParams>(filteredData)
            await AdministrationUserService.changeUserInfo({ id: user.id, data: filteredData })
            handleSuccess()
        } catch (error) {
            handleError(error)
        }
    }

    const handleSuccess = () => {
        dispatch(fetchUsers())
        handleAlertOpen('success', `User successfully ${userFormType === UserFormType.AddUser ? 'added' : 'changed'}`)
        handleClose()
        reset()
    }

    const handleError = (error) => {
        handleAlertOpen('error', error.message)
        console.error(error.message)
    }

    return isRolesLoading ? (
        <Preloader />
    ) : (
        <Dialog open={isOpenUserForm} onClose={handleClose}>
            <DialogTitle className={styles.userDialogTitle}>{titleUserForm}</DialogTitle>
            <DialogContent>
                <form
                    onSubmit={handleSubmit(
                        userFormType === UserFormType.AddUser ? onSubmitAddingUser : onSubmitEditingUser
                    )}
                >
                    <div className={styles.userForm}>
                        <div className={styles.fieldForm}>
                            <TextField
                                label="Nickname"
                                variant="standard"
                                className={styles.userFormInput}
                                placeholder="Nickname"
                                autoFocus
                                {...register('nickname', { required: 'Required field' })}
                                error={!!errors.nickname}
                                helperText={errors?.nickname ? errors.nickname.message : null}
                                disabled={userFormType !== UserFormType.AddUser}
                            />
                        </div>

                        {userFormType === UserFormType.AddUser && (
                            <div className={styles.fieldForm}>
                                <TextField
                                    label="Password"
                                    variant="standard"
                                    className={styles.userFormInput}
                                    placeholder="Password"
                                    {...register('password', { required: 'Required field' })}
                                    error={!!errors.password}
                                    helperText={errors?.password ? errors.password.message : null}
                                />
                            </div>
                        )}

                        <div className={styles.fieldForm}>
                            <TextField
                                label="First name"
                                variant="standard"
                                className={styles.userFormInput}
                                placeholder="First name"
                                {...register('firstName')}
                            />
                        </div>

                        <div className={styles.fieldForm}>
                            <TextField
                                label="Last name"
                                variant="standard"
                                className={styles.userFormInput}
                                placeholder="Last name"
                                {...register('lastName')}
                            />
                        </div>

                        <div className={styles.fieldForm}>
                            <TextField
                                label="Email"
                                variant="standard"
                                className={styles.userFormInput}
                                placeholder="Email"
                                {...register('email')}
                            />
                        </div>

                        <div className={styles.fieldForm}>
                            <TextField
                                label="Company"
                                variant="standard"
                                className={styles.userFormInput}
                                placeholder="Company"
                                {...register('company')}
                                error={!!errors.company}
                                helperText={errors?.company ? errors.company.message : null}
                            />
                        </div>

                        {isAdmin && (
                            <div className={styles.fieldForm}>
                                <TextField
                                    sx={{ width: '200px' }}
                                    select
                                    label="Role ID"
                                    variant="standard"
                                    className={styles.userFormInput}
                                    defaultValue={user.role?.id ? user.role.id : ''}
                                    {...register('roleId', { required: 'Required field' })}
                                    error={!!errors.roleId}
                                    helperText={errors?.roleId ? errors.roleId.message : null}
                                    disabled={!isAdmin}
                                >
                                    {roles.map((role) => (
                                        <MenuItem key={role.id} value={role.id}>
                                            {role.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        )}
                    </div>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">{userFormType === UserFormType.AddUser ? 'Add' : 'Edit'}</Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default UserForm

function filterDataByDefaultValues<T extends object>(data: T, defaultValues: T): T {
    return Object.keys(defaultValues).reduce((filteredData, key) => {
        if (key in data) {
            filteredData[key as keyof T] = data[key as keyof T]
        }
        return filteredData
    }, {} as T)
}
