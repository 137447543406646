import { Container } from '@mui/material'
import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback'
import { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { setCurrentGameURL } from 'store/slices/currentGameSlice'
import { useAppDispatch } from 'hooks/useRedux'
import { Page } from 'types/common'
import { setCurrentPage } from 'store/slices/currentPageSlice'
import { MainContainer } from './MainContainer'

export const MainPage: React.FC = () => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(setCurrentPage(Page.Main))
        return function cleanupCurrentURL() {
            dispatch(setCurrentGameURL(''))
        }
    }, [dispatch])

    return (
        <Container sx={{ mt: '1rem' }} maxWidth={'xl'}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <MainContainer />
            </ErrorBoundary>
        </Container>
    )
}
