import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Page } from 'types/common'

interface CurrentPageState {
    currentPage: Page
}

const initialState: CurrentPageState = {
    currentPage: Page.Main,
}

const currentPageSlice = createSlice({
    name: 'currentPage',
    initialState,
    reducers: {
        setCurrentPage: (state, action: PayloadAction<Page>) => {
            state.currentPage = action.payload
        },
    },
})

export const { setCurrentPage } = currentPageSlice.actions

export default currentPageSlice.reducer
