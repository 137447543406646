import UsersTableRow from './TableRow/UsersTableRow'
import {
    styled,
    tableCellClasses,
    Button,
    TableCell,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material'
import { User } from 'types/user'
import { Currency, IconsCurrency } from 'types/common'
import styles from './usersTable.module.scss'

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

function sortUsersByName(users: User[]): User[] {
    return users.slice().sort((a, b) => a.nickname.toLowerCase().localeCompare(b.nickname.toLowerCase()))
}

interface UsersTableProps {
    users: User[]
    isAdmin: boolean
    currency: Currency
    currencyIcon: IconsCurrency
    handleClickOpenCreditForm: ({
        realBalance,
        funBalance,
        userId,
    }: {
        realBalance: number
        funBalance: number
        userId: number
    }) => Promise<void>
    openUserForm: () => void
    changeUser: (nickname: string) => Promise<void>
    changePassword: (id: number) => Promise<void>
    handleClickRemoveUser: (user: User) => void
}

const UsersTable: React.FC<UsersTableProps> = ({
    users,
    isAdmin,
    currency,
    currencyIcon,
    handleClickOpenCreditForm,
    openUserForm,
    changeUser,
    changePassword,
    handleClickRemoveUser,
}) => {
    const sortedUsers = sortUsersByName(users)

    return (
        <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead className={styles.tableHead}>
                    <TableRow>
                        <StyledTableCell align="center">Nickname</StyledTableCell>
                        <StyledTableCell align="center">First name</StyledTableCell>
                        <StyledTableCell align="center">Role</StyledTableCell>
                        <StyledTableCell align="center">Credit(real)</StyledTableCell>
                        <StyledTableCell align="center">Credit(fun)</StyledTableCell>
                        <StyledTableCell align="center">
                            {isAdmin && (
                                <Button className={styles.button} variant="contained" onClick={openUserForm}>
                                    Add new user
                                </Button>
                            )}
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedUsers.map((user) => (
                        <UsersTableRow
                            key={user.id}
                            isAdmin={isAdmin}
                            user={user}
                            currency={currency}
                            currencyIcon={currencyIcon}
                            handleClickOpenCreditForm={handleClickOpenCreditForm}
                            changeUser={changeUser}
                            changePassword={changePassword}
                            handleClickRemoveUser={handleClickRemoveUser}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default UsersTable
