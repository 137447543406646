import React, { useEffect } from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import styles from './iframe.module.scss'
import { Page } from '../../types/common'
import Button from '@mui/material/Button'

interface IframeProps {
    URLGame: string
    closeModalIframe: (value: boolean) => void
    iframeRef: React.RefObject<HTMLIFrameElement>
    handleClickOpenCreditForm: () => void
    currentPage: Page
}

export const Iframe: React.FC<IframeProps> = ({
    URLGame,
    closeModalIframe,
    iframeRef,
    handleClickOpenCreditForm,
    currentPage,
}) => {
    useEffect(() => {
        const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth
        document.body.style.overflowY = 'hidden'
        document.body.style.paddingRight = `${scrollBarWidth}px`

        return () => {
            document.body.style.overflowY = 'auto'
            document.body.style.paddingRight = '0px'
        }
    }, [])

    return (
        <div className={styles.overlayFrameWrapper}>
            <div className={styles.overlayFrame}>
                <div className={styles.btnClose}>
                    <CloseRoundedIcon sx={{ width: '50px', height: '50px' }} onClick={() => closeModalIframe(false)} />
                </div>
                <div className={styles.iframe}>
                    <iframe
                        ref={iframeRef}
                        src={URLGame}
                        title="gameFrame"
                        style={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            border: 'none',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </div>
                {currentPage === 'main' && (
                    <Button
                        className={styles.updateCreditBtn}
                        variant="contained"
                        onClick={() => handleClickOpenCreditForm()}
                    >
                        Update credit
                    </Button>
                )}
            </div>
        </div>
    )
}
