import { useRef, useState } from 'react'
import { Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { GAME_MODE } from 'consts/constants'
import styles from './resetGame.module.scss'

interface ResetGameProps {
    handleResetGameState: (gameMode: string) => Promise<void>
}

const ResetGame: React.FC<ResetGameProps> = ({ handleResetGameState }) => {
    const [open, setOpen] = useState(false)
    const anchorRef = useRef<HTMLDivElement>(null)
    const [selectedIndex, setSelectedIndex] = useState(1)

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number): void => {
        setSelectedIndex(index)
        setOpen(false)
    }

    const handleToggle = (): void => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClick = (): void => {
        handleResetGameState(GAME_MODE[selectedIndex])
    }

    const handleClose = (event: Event): void => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return
        }
        setOpen(false)
    }

    return (
        <div className={styles.resetGameComponent}>
            <ButtonGroup variant="outlined" ref={anchorRef} aria-label="Button group with a nested menu">
                <Button onClick={handleClick}>{`Reset game state for ${GAME_MODE[selectedIndex]} mode`}</Button>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select game mode for reset"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper sx={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {GAME_MODE.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                            selected={index === selectedIndex}
                                        >
                                            {`Reset game state for ${option} mode`}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}

export default ResetGame
