import { Preloader } from 'components/Preloader/Preloader'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useEffect, useState } from 'react'
import RolesTable from './RolesTable/RolesTable'
import { RoleInfo } from 'types/user'
import { fetchPermissions } from 'store/slices/permissionsSlice'
import RoleForm from './RoleForm/RoleForm'
import { AlertColor } from '@mui/material'
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog'
import AdministrationRoleService from 'services/AdministrationRoleService'

interface EditRolesContainerProps {
    handleAlertOpen: (severityStatus: AlertColor, responseMessage: string) => void
}

const EditRolesContainer: React.FC<EditRolesContainerProps> = ({ handleAlertOpen }) => {
    const { roles, isLoading: isRolesLoading } = useAppSelector((state) => state.roles)
    const { permissions, isLoading: isPermissionsLoading } = useAppSelector((state) => state.permissions)

    const [isFormOpen, setIsFormOpen] = useState(false)
    const [selectedRole, setSelectedRole] = useState<RoleInfo>()
    const [isRemoveRoleDialogOpen, setIsRemoveRoleDialogOpen] = useState(false)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchPermissions())
    }, [dispatch])

    const handleFormOpen = (roleId?: number) => {
        const selectedRole = roleId ? roles.find((role) => role.id === roleId) : undefined
        setSelectedRole(selectedRole)
        setIsFormOpen(true)
    }

    const handleFormClose = () => {
        setIsFormOpen(false)
    }

    const handleRemoveRoleDialogOpen = (roleId: number) => {
        const selectedRole = roles.find((role) => role.id === roleId)
        setSelectedRole(selectedRole)
        setIsRemoveRoleDialogOpen(true)
    }

    const handleRemoveRoleDialogClose = () => {
        setIsRemoveRoleDialogOpen(false)
    }

    const removeRole = async (roleId: number) => {
        try {
            await AdministrationRoleService.removeRole(roleId)
            handleAlertOpen('success', 'Role removed successfully')
            setIsRemoveRoleDialogOpen(false)
        } catch (error) {
            handleAlertOpen('error', (error as Error).message)
            console.error((error as Error).message)
        }
    }

    return (
        <>
            {isRolesLoading || isPermissionsLoading ? (
                <Preloader />
            ) : (
                <>
                    <RolesTable
                        roles={roles}
                        handleFormOpen={handleFormOpen}
                        handleRemoveRoleDialogOpen={handleRemoveRoleDialogOpen}
                    />
                    <RoleForm
                        isFormOpen={isFormOpen}
                        permissions={permissions}
                        handleClose={handleFormClose}
                        handleAlertOpen={handleAlertOpen}
                    ></RoleForm>
                    <RoleForm
                        isFormOpen={isFormOpen}
                        permissions={permissions}
                        handleClose={handleFormClose}
                        role={selectedRole}
                        handleAlertOpen={handleAlertOpen}
                    ></RoleForm>
                </>
            )}
            {selectedRole && (
                <ConfirmationDialog
                    isOpen={isRemoveRoleDialogOpen}
                    title="Remove role"
                    onConfirm={removeRole.bind(undefined, selectedRole.id)}
                    onClose={handleRemoveRoleDialogClose}
                    description={`Are you sure you want to remove  '${selectedRole?.title}' role`}
                ></ConfirmationDialog>
            )}
        </>
    )
}

export default EditRolesContainer
