import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { FormControlLabel, SelectChangeEvent, Switch } from '@mui/material'
import EnvironmentSelect from './EnvironmentSelect/EnvironmentSelect'
import GamesTable from './GamesTable/GamesTable'
import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback'
import { Preloader } from 'components/Preloader/Preloader'
import GameService from 'services/GameService'
import { Game } from 'types/gameService'
import { getAllowedEnvironments, getInitEnvironment } from 'utils/userUtils'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { Page } from 'types/common'
import { setCurrentPage } from 'store/slices/currentPageSlice'
import { setFilterWorkingGames } from 'store/slices/environmentPageUISlice'
import { filterWorkingGames } from 'utils/gameUtils'
import styles from './emvironmentPage.module.scss'

const EnvironmentPage: React.FC = () => {
    const dispatch = useAppDispatch()
    const user = useAppSelector((state) => state.user.info)
    const { isOnlyWorkingGames } = useAppSelector((state) => state.environmentPageUI)

    const [environment, setEnvironment] = useState<string>('')
    const [allowedEnvironments, setAllowedEnvironments] = useState<string[]>([])
    const [games, setGames] = useState<Game[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    const handleChangeEnvironment = (event: SelectChangeEvent<string>): void => {
        setEnvironment(event.target.value)
    }

    const handleStatusGame = (): void => {
        dispatch(setFilterWorkingGames())
    }

    useEffect(() => {
        dispatch(setCurrentPage(Page.Environments))
    }, [dispatch])

    useEffect(() => {
        if (user) {
            const allowedEnvironments = getAllowedEnvironments(user.role.permissions, Page.Environments)
            setAllowedEnvironments(allowedEnvironments)

            const initEnvironment = getInitEnvironment(allowedEnvironments)
            setEnvironment(initEnvironment)
        }
    }, [user])

    useEffect(() => {
        const fetchGames = async () => {
            if (!environment) return

            setLoading(true)
            try {
                const response = await GameService.getGames(environment)
                setGames(response.data.games)
            } catch (error) {
                console.error((error as Error).message)
            } finally {
                setLoading(false)
            }
        }

        fetchGames()
    }, [environment])

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div className={styles.formGroup}>
                <FormControlLabel
                    control={<Switch checked={isOnlyWorkingGames} onChange={handleStatusGame} />}
                    label="Only working games"
                    labelPlacement="start"
                    disabled={loading}
                />
                <EnvironmentSelect
                    environment={environment}
                    handleChangeEnvironment={handleChangeEnvironment}
                    environments={allowedEnvironments}
                    disabled={loading}
                />
            </div>
            {loading ? <Preloader /> : <GamesTable games={isOnlyWorkingGames ? filterWorkingGames(games) : games} />}
        </ErrorBoundary>
    )
}

export default EnvironmentPage
