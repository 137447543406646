import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import GameService from 'services/GameService'
import { LegacyBackendGameURLParams, OSIBackendOpenGameURLParams } from 'types/gameService'

interface CurrentGameState {
    URL: string
}

const initialState: CurrentGameState = {
    URL: '',
}

export const fetchLegacyBackendGameURL = createAsyncThunk<string, LegacyBackendGameURLParams, { rejectValue: string }>(
    'currentGame/fetchGameURL',
    async function (params, { rejectWithValue, dispatch }) {
        try {
            const response = await GameService.getLegacyGameURL(params)
            const URL = response.data.url
            dispatch(setCurrentGameURL(URL))
            return URL
        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchOSIBackendGameURL = createAsyncThunk<string, OSIBackendOpenGameURLParams, { rejectValue: string }>(
    'currentGame/fetchGameURL',
    async function (params, { rejectWithValue, dispatch }) {
        try {
            const response = await GameService.getOSIGameURL(params)
            const URL = response.data.url
            dispatch(setCurrentGameURL(URL))
            return URL
        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    }
)

const currentGameSlice = createSlice({
    name: 'currentGame',
    initialState,
    reducers: {
        setCurrentGameURL: (state, action: PayloadAction<string>) => {
            state.URL = action.payload
        },
    },
})

export const { setCurrentGameURL } = currentGameSlice.actions

export default currentGameSlice.reducer
