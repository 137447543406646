import React, { useRef, useState } from 'react'
import { Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Currency, IconsCurrency } from 'types/common'
import styles from './creditReal.module.scss'

interface CreditRealProps {
    handleClickOpenCreditForm: () => void
    handleChangeCurrency: (currency: Currency) => void
    realBalanceInCurrency: number
    currency: Currency
}

const CreditReal: React.FC<CreditRealProps> = ({
    handleClickOpenCreditForm,
    handleChangeCurrency,
    realBalanceInCurrency,
    currency,
}) => {
    const [open, setOpen] = useState(false)
    const anchorRef = useRef<HTMLDivElement>(null)

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number): void => {
        handleChangeCurrency(Object.values(Currency)[index])
        setOpen(false)
    }

    const handleToggle = (): void => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClick = (): void => {
        handleClickOpenCreditForm()
    }

    const handleClose = (event: Event): void => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return
        }
        setOpen(false)
    }

    return (
        <>
            <ButtonGroup
                variant="outlined"
                color="inherit"
                ref={anchorRef}
                aria-label="Button group with a nested menu"
            >
                <Button onClick={handleClick}>
                    <div
                        className={styles.creditReal}
                    >{`Credit Real:  ${realBalanceInCurrency} ${IconsCurrency[currency]}`}</div>
                </Button>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select game mode for reset"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper sx={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {Object.values(Currency).map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                            selected={currency === option}
                                        >
                                            {`${option}`}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )
}

export default CreditReal
