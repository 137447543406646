import React from 'react'
import { Game } from 'types/gameService'
import { styled, TableCell, tableCellClasses } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import { extractGameTimestampAndVersion } from 'utils/gameUtils'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

interface GamesTableRowProps {
    game: Game
    orderNumber: number
}

const GamesTableRow: React.FC<GamesTableRowProps> = ({ game, orderNumber }) => {
    const { gameClass, mathFileName, mathFileHash } = game
    const releaseInfo = extractGameTimestampAndVersion(game)

    return (
        <StyledTableRow>
            <StyledTableCell align="center">{orderNumber}</StyledTableCell>
            <StyledTableCell align="center">{gameClass}</StyledTableCell>
            <StyledTableCell align="center">{mathFileName}</StyledTableCell>
            <StyledTableCell align="center">{mathFileHash}</StyledTableCell>
            <StyledTableCell align="center">{releaseInfo}</StyledTableCell>
        </StyledTableRow>
    )
}

export default GamesTableRow
