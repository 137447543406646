import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { User } from 'types/user'

interface UserRemoveDialogProps {
    selectedUser: User
    isOpenUserRemoveDialog: boolean
    removeUser: (id: number) => Promise<void>
    setIsOpenUserRemoveDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const UserRemoveDialog: React.FC<UserRemoveDialogProps> = ({
    selectedUser,
    isOpenUserRemoveDialog,
    setIsOpenUserRemoveDialog,
    removeUser,
}) => {
    const handleClose = () => {
        setIsOpenUserRemoveDialog(false)
    }

    const handleRemoveUser = () => {
        removeUser(selectedUser.id)
        setIsOpenUserRemoveDialog(false)
    }

    return (
        <Dialog
            open={isOpenUserRemoveDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ textAlign: 'center' }} id="alert-dialog-title">
                {'Remove user'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to remove {selectedUser.nickname}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleRemoveUser} autoFocus>
                    Remove
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UserRemoveDialog
