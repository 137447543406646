import React from 'react'
import { ROUTE } from 'consts/constants'
import styles from './links.module.scss'
import NavigationLink from './NavigationLink/NavigationLink'

interface NavigationLinksProps {
    isEnvsPageAllowed: boolean
    isMenuActive: boolean
    toggleMenuActive: () => void
}

const NavigationLinks: React.FC<NavigationLinksProps> = ({ isEnvsPageAllowed, isMenuActive, toggleMenuActive }) => (
    <div className={`${styles.linksGroup} ${isMenuActive ? styles.linksGroup_active : ''}`}>
        <NavigationLink to={ROUTE.MAIN} label="Home" toggleMenuActive={toggleMenuActive} />

        {isEnvsPageAllowed && (
            <>
                <NavigationLink to={ROUTE.ENVIRONMENTS} label="ENVS" toggleMenuActive={toggleMenuActive} />
                <NavigationLink
                    to={ROUTE.LIVE_ENV_MONITORING}
                    label="Live Env Monitoring"
                    toggleMenuActive={toggleMenuActive}
                />
            </>
        )}
    </div>
)

export default NavigationLinks
