import { GameWithEnvironmentReleaseInfo } from 'types/gameService'
import {
    styled,
    tableCellClasses,
    TableCell,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material'
import { sortGamesByClass } from 'utils/gameUtils'
import { GameSortOrder } from 'types/common'
import LiveEnvMonitoringTableRow from './LiveEnvMonitoringTableRow/LiveEnvMonitoringTableRow'
import styles from './liveEnvMonitoringTable.module.scss'

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

interface LiveEnvMonitoringTableProps {
    games: GameWithEnvironmentReleaseInfo[]
}

const LiveEnvMonitoringTable: React.FC<LiveEnvMonitoringTableProps> = ({ games }) => {
    const sortedGamesByGameClass = sortGamesByClass(games, GameSortOrder.AtoZ)

    return (
        <TableContainer className={styles.tableContainer} component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead className={styles.tableHead}>
                    <TableRow>
                        <StyledTableCell align="center">#</StyledTableCell>
                        <StyledTableCell align="center">gameClass</StyledTableCell>
                        <StyledTableCell align="center">fileName</StyledTableCell>
                        <StyledTableCell align="center">fileHash</StyledTableCell>
                        <StyledTableCell align="center">gameVersion LIVE</StyledTableCell>
                        <StyledTableCell align="center">gameVersion DEV</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedGamesByGameClass.map((game, index) => (
                        <LiveEnvMonitoringTableRow game={game} key={index} orderNumber={index + 1} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default LiveEnvMonitoringTable
