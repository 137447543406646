import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import UserService from 'services/UserService'
import { BalanceMode } from 'types/common'
import { Balance, User } from 'types/user'

interface CurrentBalanceData {
    real: Balance
    fun: Balance
}
interface UserState {
    info: User | undefined
    currentBalanceData: CurrentBalanceData
    allowedEnvironments: string[]
}

const initialState: UserState = {
    info: undefined,
    currentBalanceData: {
        real: {
            mode: BalanceMode.REAL,
            value: 0,
        },
        fun: {
            mode: BalanceMode.FUN,
            value: 0,
        },
    },
    allowedEnvironments: [],
}

export const fetchUserBalance = createAsyncThunk(
    'userData/getUserBalance',
    async function (_, { rejectWithValue, dispatch }) {
        try {
            const response = await UserService.getBalanceAuthenticatedUser()
            const { balance } = response.data

            dispatch(updateBalance(balance))
            return balance
        } catch (error) {
            return rejectWithValue((error as Error).message)
        }
    }
)

const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<User>) => {
            state.info = action.payload
            updateBalanceData(state, action.payload.balance)
        },

        updateBalance: (state, action: PayloadAction<Balance[]>) => {
            updateBalanceData(state, action.payload)
        },

        setAllowedEnvironments: (state, action: PayloadAction<string[]>) => {
            state.allowedEnvironments = action.payload
        },
    },
})

export const { setUserData, updateBalance, setAllowedEnvironments } = userDataSlice.actions

export default userDataSlice.reducer

function updateBalanceData(state: UserState, balance: Balance[]): void {
    const creditReal = balance.find((balance) => balance.mode === BalanceMode.REAL)?.value || 0
    const creditFun = balance.find((balance) => balance.mode === BalanceMode.FUN)?.value || 0
    state.currentBalanceData.real.value = creditReal
    state.currentBalanceData.fun.value = creditFun
}
