import { GameStatus } from 'types/gameService'

const gameStatusIconPath: Partial<Record<GameStatus, string>> = {
    GAME_NOT_CONFIGURED: '/img/gameNotConfigured.png',
    ASSETS_NOT_INSTALLED: '/img/assetsNotInstalled.png',
    MATH_NOT_INSTALLED: '/img/mathNotInstalled.png',
    NOT_AVAILABLE: '/img/gameNotAvalible.png',
    DISABLED: '/img/gameDisabled.png',
}

export function getGameIconPath(gameStatus: GameStatus): string | undefined {
    return gameStatusIconPath[gameStatus]
}
