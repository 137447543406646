import React from 'react'
import { Currency, IconsCurrency } from 'types/common'
import styles from './userInfo.module.scss'
import { Button } from '@mui/material'
import CreditRealContainer from './CreditReal/CreditRealContainer'

interface UserInfoProps {
    userName?: string
    realBalanceInCurrency: number
    funBalanceInCurrency: number
    currency: Currency
    handleClickOpenCreditForm: () => void
}

const UserInfo: React.FC<UserInfoProps> = ({
    userName,
    realBalanceInCurrency,
    funBalanceInCurrency,
    currency,
    handleClickOpenCreditForm,
}) => (
    <div className={styles.userInfo}>
        {userName && <div className={styles.userName}>{userName}</div>}
        <CreditRealContainer
            handleClickOpenCreditForm={handleClickOpenCreditForm}
            realBalanceInCurrency={realBalanceInCurrency}
            currency={currency}
        ></CreditRealContainer>
        <Button variant="outlined" color="inherit" onClick={handleClickOpenCreditForm} className={styles.creditFun}>
            Credit Fun: {funBalanceInCurrency} {IconsCurrency.FUN}
        </Button>
    </div>
)

export default UserInfo
