import { useState, ChangeEvent } from 'react'
import { TextField, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

interface SearchProps {
    handleSearch: (value: string) => void
}

export const Search: React.FC<SearchProps> = ({ handleSearch }) => {
    const [value, setValue] = useState<string>('')

    const updateValueSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const valueSearch = e.target.value
        setValue(valueSearch)
        handleSearch(valueSearch)
    }

    return (
        <TextField
            label="Search game"
            value={value}
            onChange={updateValueSearch}
            variant="standard"
            fullWidth
            sx={{
                mb: '1.5rem',
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    )
}
