import { FC, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback'
import { Preloader } from 'components/Preloader/Preloader'
import { ENVIRONMENT } from 'consts/constants'
import useFetchGames from 'hooks/useFetchGames'
import LiveEnvMonitoringTable from './LiveEnvMonitoringTable/LiveEnvMonitoringTable'
import { extractGameInfo, extractGameTimestampAndVersion, gameVersionsEqual } from 'utils/gameUtils'
import { GameWithEnvironmentReleaseInfo } from 'types/gameService'
import { useAppDispatch } from 'hooks/useRedux'
import { setCurrentPage } from 'store/slices/currentPageSlice'
import { Page } from 'types/common'

const LiveEnvMonitoringPage: FC = () => {
    const dispatch = useAppDispatch()
    const [isLoadingLiveGames, liveGames] = useFetchGames(ENVIRONMENT.LIVE)
    const [isLoadingDevGames, devGames] = useFetchGames(ENVIRONMENT.DEV)

    useEffect(() => {
        dispatch(setCurrentPage(Page.LiveEnvMonitoring))
    }, [dispatch])

    const liveGamesWithEnvReleaseInfo: GameWithEnvironmentReleaseInfo[] = liveGames.map((game) => {
        const gameDevInfo = extractGameInfo(devGames, game.gameClass)
        const gameLiveReleaseInfo = extractGameTimestampAndVersion(game)
        if (gameDevInfo) {
            const gameDevReleaseInfo = extractGameTimestampAndVersion(gameDevInfo)
            const liveAndDevHasEqualVersion = gameVersionsEqual(game, gameDevInfo)

            return { ...game, gameDevReleaseInfo, gameLiveReleaseInfo, liveAndDevHasEqualVersion }
        }
        return { ...game, gameLiveReleaseInfo }
    })

    const isLoading = isLoadingLiveGames && isLoadingDevGames

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {isLoading ? <Preloader /> : <LiveEnvMonitoringTable games={liveGamesWithEnvReleaseInfo} />}
        </ErrorBoundary>
    )
}

export default LiveEnvMonitoringPage
