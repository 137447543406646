import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AdministrationRoleService from 'services/AdministrationRoleService'
import { RoleInfo } from 'types/user'

interface RolesState {
    roles: RoleInfo[]
    isLoading: boolean
    error: string | null | undefined
}

const initialState: RolesState = {
    roles: [],
    isLoading: false,
    error: null,
}

export const fetchRoles = createAsyncThunk<RoleInfo[], void, { rejectValue: string }>(
    'roles/fetchRoles',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AdministrationRoleService.getUserRoles()
            return response.data.roles
        } catch (error) {
            return rejectWithValue((error as Error).message)
        }
    }
)

const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoles.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchRoles.fulfilled, (state, action) => {
                state.isLoading = false
                state.roles = action.payload
            })
            .addCase(fetchRoles.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.payload
            })
    },
})

export default rolesSlice.reducer
