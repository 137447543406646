import axios from 'axios'
import { ROUTE } from '../../consts/constants'

const $api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    return config
})

$api.interceptors.response.use(
    (config) => {
        return config
    },
    async (error) => {
        const originalRequest = error.config
        // Check an access token has expired
        if (error.response.status === 401 && error.config && !error.config._isRetry) {
            originalRequest._isRetry = true
            try {
                // If the access token has expired, use refresh token to update access token
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}authentication/refresh?refreshToken=${localStorage.getItem('tokenR')}`
                )
                localStorage.setItem('token', response.data.accessToken)
                return $api.request(originalRequest)
            } catch (e) {
                localStorage.removeItem('token')
                localStorage.removeItem('tokenR')
                window.location.href = ROUTE.LOGIN
            }
        }
        throw error
    }
)

export default $api
