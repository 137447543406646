import { useEffect, useState } from 'react'
import { BalanceMode, Currency, IconsCurrency } from 'types/common'
import { User } from 'types/user'
import { extractUserBalance } from 'utils/userUtils'
import { Button, InputAdornment, styled, TableCell, tableCellClasses } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import styles from './UsersTableRow.module.scss'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

interface UsersTableRowProps {
    isAdmin: boolean
    user: User
    currency: Currency
    currencyIcon: IconsCurrency
    handleClickOpenCreditForm: ({
        realBalance,
        funBalance,
        userId,
    }: {
        realBalance: number
        funBalance: number
        userId: number
    }) => Promise<void>
    changeUser: (nickname: string) => Promise<void>
    changePassword: (id: number) => Promise<void>
    handleClickRemoveUser: (user: User) => void
}

const UsersTableRow: React.FC<UsersTableRowProps> = ({
    isAdmin,
    user,
    currency,
    currencyIcon,
    handleClickOpenCreditForm,
    changeUser,
    changePassword,
    handleClickRemoveUser,
}) => {
    const [realBalance, setRealBalance] = useState<number>(0)
    const [funBalance, setFunBalance] = useState<number>(0)

    useEffect(() => {
        const realBalance =
            extractUserBalance({ userBalance: user.balance, balanceMode: BalanceMode.REAL, currency }) ?? 0
        const funBalance =
            extractUserBalance({ userBalance: user.balance, balanceMode: BalanceMode.FUN, currency }) ?? 0
        setRealBalance(realBalance)
        setFunBalance(funBalance)
    }, [currency, user.balance])

    return (
        <StyledTableRow>
            <StyledTableCell align="center" component="th" scope="row">
                {user.nickname}
            </StyledTableCell>
            <StyledTableCell align="center">{user.firstName}</StyledTableCell>
            <StyledTableCell align="center">{user.role.title}</StyledTableCell>
            <StyledTableCell align="center">
                <button
                    onClick={() => handleClickOpenCreditForm({ realBalance, funBalance, userId: user.id })}
                    className={styles.creditButton}
                >
                    {realBalance}
                    <InputAdornment position="end">{currencyIcon}</InputAdornment>
                </button>
            </StyledTableCell>
            <StyledTableCell align="center">
                <button
                    onClick={() => handleClickOpenCreditForm({ realBalance, funBalance, userId: user.id })}
                    className={styles.creditButton}
                >
                    {funBalance}
                    <InputAdornment position="end">{currencyIcon}</InputAdornment>
                </button>
            </StyledTableCell>

            <StyledTableCell align="center">
                <div className={styles.btnGroup}>
                    <Button
                        variant="contained"
                        size="small"
                        className={styles.button}
                        onClick={() => {
                            changeUser(user.nickname)
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        className={styles.button}
                        onClick={() => {
                            changePassword(user.id)
                        }}
                    >
                        Reset password
                    </Button>
                    {isAdmin && (
                        <Button
                            className={styles.button}
                            variant="contained"
                            size="small"
                            onClick={() => {
                                handleClickRemoveUser(user)
                            }}
                        >
                            Remove
                        </Button>
                    )}
                </div>
            </StyledTableCell>
        </StyledTableRow>
    )
}

export default UsersTableRow
