import {
    styled,
    tableCellClasses,
    TableCell,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material'
import styles from './gameTagsTable.module.scss'
import GameTagsTableRow from './TableRow/GameTagsTableRow'
import { GameInfo, GameTag, Tag } from 'types/adminService'

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

interface GameTagsTableProps {
    gamesInfo: GameInfo[]
    tags: Tag[]
    handleAddTag: (newTag: GameTag) => void
    handleRemoveTag: (gameId: number, tagId: number) => Promise<void>
}

const GameTagsTable: React.FC<GameTagsTableProps> = ({ gamesInfo, tags, handleAddTag, handleRemoveTag }) => {
    return (
        <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead className={styles.tableHead}>
                    <TableRow>
                        <StyledTableCell align="center">gameUID</StyledTableCell>
                        <StyledTableCell align="center">gameName</StyledTableCell>
                        <StyledTableCell align="center">gameClass</StyledTableCell>
                        <StyledTableCell align="center">tags</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {gamesInfo.map((gameInfo) => (
                        <GameTagsTableRow
                            key={gameInfo.id}
                            game={gameInfo}
                            tags={tags}
                            handleAddTag={handleAddTag}
                            handleRemoveTag={handleRemoveTag}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default GameTagsTable
